
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField, InputAdornment, Typography, LinearProgress, Tooltip, Fab } from '@material-ui/core';
import { getProcessOrder, deleteProcessOrder, getProcessOrderForCSV } from '../rest/ProcessOrderRequests';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import copy from 'fast-copy';
import PropTypes from 'prop-types';
import HighheatStepOverviewComponent from './highheat-step-overview-component';
import MixingStepOverviewComponent from './mixing-step-overview-component';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OrderSummary from '../Orders/order-summary';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as ProcOrderRequests from '../rest/ProcessOrderRequests';
import * as ProcessStepRequests from '../rest/ProcessStepRequests';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirmationDialog from '../Dialogs/delete-confirmation-dialog';
import { CSVLink } from 'react-csv';
import { styled } from '@material-ui/styles';

const DownloadIcon = styled(GetAppIcon)({
  color: 'white',
});

const SubmitIcon = styled(ArrowForwardIcon)({
  color: 'white',
});

const styles = {
  root: {
    justify: 'center',
    padding: 15,
  },
};

class ProcessOrderOverviewModule extends Component {

  constructor() {

    super();

    this.state = {
      loading: true,
      highheat_loading: true,
      mixing_loading: true,
      mixing_step: {},
      highheat_step: {},
      deleteDialogOpen: false,
      deletePending: false,
      csv_data: [],
    };
    this.fetchProcessOrder = this.fetchProcessOrder.bind(this);
    this.fetchMixingStep = this.fetchMixingStep.bind(this);
    this.updateLoadingHighheat = this.updateLoadingHighheat.bind(this);
    this.fetchHigHeatStep = this.fetchHigHeatStep.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  componentDidMount() {
    this.fetchProcessOrder();
    this.fetchMixingStep();
    this.fetchHigHeatStep();
    this.downloadCSV();
  }

  async downloadCSV() {

    let incoming_parts;
    try {
      let resp = await getProcessOrderForCSV(this.props.match.params.id);
      if (resp.status === 200) {
        incoming_parts = await resp.json();
      } else {
        this.props.postErrorSnack('API responded with non 200, cannot download.');
        return;
      }

    } catch (e) {
      this.props.postErrorSnack('Could not call API, cannot download.');
      return;
    }
    this.setState({ csv_data: [incoming_parts.headers].concat(incoming_parts.data) });
  }

  async fetchMixingStep() {
    try {
      const id = this.props.match.params.id;
      let resp = await ProcessStepRequests.getProcessStep(id, 'mixing');
      if (resp.status === 200) {
        let step = await resp.json();
        if (Object.entries(step).length === 0 && step.constructor === Object) {
          this.props.postErrorSnack('Mixing step did not exist.');
        } else {
          this.setState({ mixing_step: step });
          this.props.postSuccessSnack('Retrieved mixing step.');
        }
      } else {
        let err = await resp.json();
        this.props.postErrorSnack(`Error: Could not fetch mixing step (non 200 response) ${err}.`);
      }
    } catch (e) {
      this.props.postErrorSnack(`Error: Could not fetch mixing step ${e}.`);
    }
    this.setState({ mixing_loading: false });
  }

  async fetchHigHeatStep() {
    try {
      const id = this.props.match.params.id;
      let resp = await ProcessStepRequests.getProcessStep(id, 'highheat');
      if (resp.status === 200) {
        let step = await resp.json();
        if (Object.entries(step).length === 0 && step.constructor === Object) {
          this.props.postErrorSnack('High Heat step did not exist.');
        } else {
          this.setState({ highheat_step: step });
          this.props.postSuccessSnack('Retrieved high heat step.');
        }
      } else {
        let err = await resp.json();
        this.props.postErrorSnack(`Error: Could not fetch high heat step (non 200 response) ${err}.`);
      }
    } catch (e) {
      this.props.postErrorSnack(`Error: Could not fetch high heat step ${e}.`);
    }
    this.setState({ highheat_loading: false });
  }

  async handleSubmitButton() {
    let resp = await ProcOrderRequests.submitOrder(this.state.order.process_order_id || '');
    if (resp.status !== 200) {
      let msg = await resp.json();
      this.props.postErrorSnack(msg, 10000);
    }
    else {
      let {order} = this.state;
      order.status = 'IN PROGRESS';
      this.setState({order: order});
    }
  }

  async fetchProcessOrder() {

    const id = this.props.match.params.id;
    try {

      let resp = await getProcessOrder(id);
      if (resp.status === 200) {
        let incoming_order = await resp.json();
        if (Object.entries(incoming_order).length === 0 && incoming_order.constructor === Object) {
          this.props.postErrorSnack('Process order did not exist.');
        } else {
          this.setState({ order: incoming_order });
          this.props.postSuccessSnack('Retrieved process order.');
        }
      } else {
        let err = await resp.json();

        this.props.postErrorSnack(`Error: Could not fetch process order (non 200 response) ${err}.`);
      }
    } catch (e) {
      this.props.postErrorSnack(`Error: Could not fetch process order ${e}.`);
    }

    this.setState({ loading: false });
  }

  updateLoadingHighheat(load_state) {
    const state = copy(this.state);
    state.highheat_loading = load_state;
    this.setState(state);
  }

  /**
  * Closes the delete confirmation dialog.
  */
  closeDeleteDialog() {

    this.setState({
      deleteDialogOpen: false
    });
  }

  /**
   * Opens the delete confirmation dialog.
   */
  openDeleteDialog() {

    this.setState({
      deleteDialogOpen: true,
    });
  }

  /**
   * Handles deleting the process order and
   * redirecting the user to the orders table.
   */
  async handleDeleteConfirmed() {

    this.setState({ deletePending: true });

    try {

      const resp = await deleteProcessOrder(this.state.order.pk);

      if (resp.status === 200) {

        // Indicate success & go back to the table
        this.props.postSuccessSnack('Process order deleted');
        this.props.history.push('/app/orders/');

      } else {

        this.props.postErrorSnack('Error: Failed to delete process order');
      }

    } catch (e) {

      this.props.postErrorSnack('Error: Failed to delete process order');

    } finally {

      this.setState({ deletePending: false });
      this.closeDeleteDialog();
    }
  }

  render() {
    const { order } = this.state;

    const {
      pk,
      highheat_template_step_id,
      mixing_template_step_id,
      name,
      part_quantity,
      promise_date,
      status,
      total_weight,
      total_weight_tolerance,
      created,
      // total_height,
      // vertical_spacing,
      // number_of_layers,
      // part_height,
      // remaining_powder_mass,
      // setup_powder_mass,
    } = order ? order : {
      pk: null,
      highheat_template_step_id: null,
      mixing_template_step_id: null,
      name: null,
      number_of_layers: null,
      part_height: null,
      part_quantity: null,
      promise_date: null,
      remaining_powder_mass: null,
      setup_powder_mass: null,
      status: null,
      total_height: null,
      total_weight: null,
      total_weight_tolerance: null,
      vertical_spacing: null,
      created: null,
    };

    return (
      <Fragment>
        <Grid container direction='column' alignItems="stretch" spacing={16}>
          <Grid item xs={12}>
            {this.state.loading ? <LinearProgress /> : <Fragment />}
            <Paper style={styles.root}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                <Typography variant='h5'>Process Order Detailed View</Typography>
              </div>
              <br />
              {order ?
                <Grid container direction='row' spacing={16} alignItems="stretch">
                  <Grid item xs={3} />
                  <Grid item xs={6}>
                    <TextField fullWidth
                      label='Process Order Name'
                      // type='number'
                      value={name || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        // endAdornment: <InputAdornment position="end">test</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={4}>
                    <TextField fullWidth
                      label='Created On'
                      // type='number'
                      value={created || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        // endAdornment: <InputAdornment position="end">test</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField fullWidth
                      label='Promised By'
                      // type='number'
                      value={promise_date || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        // endAdornment: <InputAdornment position="end">test</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField fullWidth
                      label='Status'
                      // type='number'
                      value={status || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        // endAdornment: <InputAdornment position="end">test</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <TextField fullWidth
                      label='Number of Parts'
                      type='number'
                      value={part_quantity || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        // endAdornment: <InputAdornment position="end">test</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField fullWidth
                      label='Total Weight'
                      type='number'
                      value={total_weight || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        endAdornment: <InputAdornment position="end">grams</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField fullWidth
                      label='Total Weight Tolerance'
                      type='number'
                      value={total_weight_tolerance || ''}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: '#303030' },
                      }}
                      variant="outlined"
                      InputProps={{
                        style: { color: '#303030' },
                        endAdornment: <InputAdornment position="end">grams</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  {/* <Grid item xs={3}>
                    <TextField fullWidth
                      label='Number of Layers'
                      type='number'
                      value={number_of_layers}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#303030'},
                      }}
                      variant="outlined"
                      InputProps={{
                        style: {color: '#303030'},
                      }}
                    />
                  </Grid> */}
                </Grid>
                : <Fragment />}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {this.state.mixing_loading ? <LinearProgress /> : <Fragment />}
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body1">Mixing Step Detailed View</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  {mixing_template_step_id !== null ?
                    <MixingStepOverviewComponent
                      {...this.props}
                      step={this.state.mixing_step}
                    /> : <Fragment />}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            {this.state.highheat_loading ? <LinearProgress /> : <Fragment />}
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body1">Highheat Step Detailed View</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  {highheat_template_step_id !== null ?
                    <HighheatStepOverviewComponent
                      {...this.props}
                      step={this.state.highheat_step}
                    /> : <Fragment />}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            {this.state.loading ? <LinearProgress /> : <Fragment />}
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body1">Process Order Summary</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{width: '100%'}}>
                  {
                    pk !== null ? (
                      <OrderSummary orderId={pk} />
                    ) : (
                      <Typography variant="body2">Waiting for process order to load.</Typography>
                    )
                  }
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        {status === 'READY' ?
          <Tooltip
            title='Submit'
            aria-label='Submit'
            style={{
              right: 18,
              bottom: 120,
              position: 'fixed',
              backgroundColor: '#00a152',
            }}>
            <Fab
              onClick={() => { this.handleSubmitButton(); }}>
              <SubmitIcon />
            </Fab>
          </Tooltip>
          : <Fragment />}
        {this.state.csv_data.length !== 0 ?
          <CSVLink
            data={this.state.csv_data}
            filename={`order_${this.props.match.params.id}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            <Tooltip
              title='Download CSV'
              aria-label='Download CSV'
              style={{
                right: 18,
                bottom: 40,
                position: 'fixed',
                backgroundColor: '#283593',
              }}>
              <Fab>
                <DownloadIcon />
              </Fab>
            </Tooltip>
          </CSVLink>
          : <Fragment />}
        <Tooltip
          title='Delete'
          aria-label='Delete'
          style={{
            left: 18,
            bottom: 40,
            position: 'fixed',
            backgroundColor: '#DC2700',
          }}>
          <Fab
            color="secondary"
            onClick={this.openDeleteDialog}>
            <DeleteIcon />
          </Fab>
        </Tooltip>
        <DeleteConfirmationDialog
          open={this.state.deleteDialogOpen}
          handleClose={this.closeDeleteDialog}
          handleDeleteConfirmed={this.handleDeleteConfirmed}
          title={'Are you sure you want to delete this process order?'}
          text={name ? name : ''}
          deletePending={this.state.deletePending}
        />
      </Fragment >
    );
  }
}

ProcessOrderOverviewModule.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(ProcessOrderOverviewModule);
