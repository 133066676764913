import {Paper} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import MixingStepComponent from './mixing-step-component';
import PropTypes from 'prop-types';

const styles = {
  root: {
    justify: 'center',
    padding: 15,
  },
};

class MixingStepModule extends Component {

  constructor() {

    super();

    this.state = {};
    this.onMixingStepSaved = this.onMixingStepSaved.bind(this);
    // this.fetchMixingTemplate = this.fetchMixingTemplate.bind(this);
  }

  onMixingStepSaved() {
    this.props.history.push('/app/processSteps/mixingsteptable');
  }

  componentDidMount() {
    // this.fetchMixingTemplate();
  }

  render() {
    return (
      <Fragment>
        <Paper style={styles.root}>
          <MixingStepComponent
            {...this.props}
            onSaved={this.onMixingStepSaved}
          />
        </Paper>
      </Fragment>

    );
  }
}

MixingStepModule.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(MixingStepModule);
