import { makeRequest } from './BaseRequest';

const SCALE_FACTOR_ENDPOINT = '/scale_factor';

export function getScaleFactor(od, id, height, mixing_step_id) {
  return makeRequest(`${SCALE_FACTOR_ENDPOINT}?part_od=${od}&part_id=${id}&part_height=${height}&template_mixing_step_id=${mixing_step_id}`, 'GET');
}

const MATERIAL_FORMULATION_ENDPOINT = '/material_formulation';
export function getMaterialFormulation(power_slope, wheel_wear_slope, ratio_slope) {
  return makeRequest(`${MATERIAL_FORMULATION_ENDPOINT}?power_slope=${power_slope}&wheel_wear_slope=${wheel_wear_slope}&ratio_slope=${ratio_slope}`, 'GET');
}
// https://tamino-api.dev.tamino.3m-cloud.com/scale_factor?part_od=21.5&part_id=6.5&part_height=10.5&grit_size=107&com=.19