/**
 *  Order summary component displays the following order details:
 *      - Density of material
 *      - Average surface roughness (Ra, Rz)
 *      - Composition of the mix
 *          - Type of material
 *          - Mass of each
 *          - Percentages of each (actual and target)
 *      - Tmax - Maximum sintering temperature
 *      - Thold - Time at the maximum temperature
 *      - Porosity violin plot (visualizes distribution of the parts)
 *      - Keyence/Mass plots (visualizes distribution of the parts)
 *      - Cut Performance plots
 *          - Q' vs Spindle Power
 *          - Q' vs Tangential Force
 *          - Q' vs Wear
 *
 *  The components requires the following props:
 *      - orderId: Process order id.
 *
 *  @author José Araujo <jaraujo@mmm.com>
 */

import React, { Component, Fragment } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  Paper,
  List,
  Divider,
  LinearProgress,
  GridList,
  GridListTile,
  ListItemText,
  Card
} from '@material-ui/core';

import PropTypes from 'prop-types';
import * as PartsRequests from '../rest/PartsRequests';
import * as ProcessStepRequests from '../rest/ProcessStepRequests';
import Plot from '../Plots/plot';


function snakeCaseToCapitalizeCase(snakeCase) {
  return snakeCase.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

const styles = ({});

class OrderSummary extends Component {
  /**
   *  Plot are broken down into two groups:
   *      qc: Plots of part qc cut performance summary data.
   *      stages: Plots of part instrument data at different stage.
   *
   *  NOTE: QC data is multi-dimensional, where stage data are single-dimenstional. This requires
   *  two separate plot data setter methods.
   *
   *  Plots require:
   *      title: The title to be displayed at the top of the plot.
   *      xAxisField: The fetched data field name to plot on the x-axis.
   *      xAxisTitle: The title to be displayed at the x-axis of the plot.
   *      yAxisField: The fetched data field name to plot on the y-axis.
   *      yAxisTitle: The title to be displayed at the y-axis of the plot.
   *  Plots options:
   *      size: Used to set the height and width of the plot. Otherwise styled to have 100% height
   *      and width.
   */
  state = {
    isLoading: true,
    hasQcMeta: false,
    hasStageMeta: false,
    hasMixingStepMeta: false,
    hasHighHeatStepMeta: false,
    plots: {
      qc: {
        specificPowerPlot: {
          title: 'Specific Power',
          xAxisField: 'q_prime',
          xAxisTitle: 'Q\'',
          yAxisField: 'specific_power',
          yAxisTitle: 'Specific Power'
        },
        wearPlot: {
          title: 'Wheel Wear',
          xAxisField: 'q_prime',
          xAxisTitle: 'Q\'',
          yAxisField: 'wear',
          yAxisTitle: 'Wheel Wear'
        },
        normalForcePlot: {
          title: 'Specific Normal Force',
          xAxisField: 'q_prime',
          xAxisTitle: 'Q\'',
          yAxisField: 'normal_force',
          yAxisTitle: 'Specific Force'
        },
        specificTangentialForcePlot: {
          title: 'Specific Tangential Force',
          xAxisField: 'q_prime',
          xAxisTitle: 'Q\'',
          yAxisField: 'specific_tangential_force',
          yAxisTitle: 'Specific Tangential Force'
        },
        initialWorkpieceMassPlot: {
          title: 'Initial Workpiece Mass',
          xAxisField: 'q_prime',
          xAxisTitle: 'Q\'',
          yAxisField: 'initial_workpiece_mass',
          yAxisTitle: 'Initial Workpiece Mass'
        }
      },
      stages: {
        massPlot: {
          title: 'Mass',
          stages: ['green_stage', 'sintered_stage'],
          colors: ['green', 'red'],
          xAxisTitle: 'Stage',
          yAxisField: 'mass',
          yAxisTitle: 'Mass'
        },
        porosityPlot: {
          title: 'Porosity',
          stages: ['green_stage', 'sintered_stage'],
          colors: ['green', 'red'],
          xAxisTitle: 'Stage',
          yAxisField: 'porosity',
          yAxisTitle: 'Porosity'
        }
      }
    },
    precision: 3
  };

  componentDidMount() {
    this.fetchOrderPartData();
    this.fetchOrderMixingStepData();
    this.fetchOrderHighHeatStepData();
  }

  async fetchOrderStepData(stepName) {
    let { orderId } = this.props;
    let res = await ProcessStepRequests.getProcessStep(orderId, stepName);
    return await res.json();
  }

  async fetchOrderHighHeatStepData() {
    let highHeatMeta = await this.fetchOrderStepData('highheat');
    this.setState({
      highHeatStep: highHeatMeta,
      hasHighHeatStepMeta: Object.keys(highHeatMeta).length > 0 ? true : false
    });
  }

  async fetchOrderMixingStepData() {
    let mixingStepMeta = await this.fetchOrderStepData('mixing');
    this.setState({
      isLoading: false,
      mixingStep: mixingStepMeta,
      hasMixingStepMeta: Object.keys(mixingStepMeta).length > 0 ? true : false
    });
  }

  async fetchOrderPartData() {
    let { orderId } = this.props;
    let res = await PartsRequests.getParts(orderId);
    let res_json = await res.json();
    let qcCutSummaryData = res_json.filter(part => {
      return part['qc'];
    });
    let hasStageMeta = false;
    for (const part of res_json) {
      for (const key of Object.keys(part)) {
        if (key.includes('stage')) {
          hasStageMeta = true;
        }
      }
    }
    this.setState({
      parts: res_json,
      qcCutSummaryData: qcCutSummaryData,
      hasQcMeta: qcCutSummaryData.length > 0 ? true : false,
      hasStageMeta: hasStageMeta
    }, () => { this.setPlotsData(); });
  }

  setPlotsData = () => {
    let plots = this.state.plots;

    if (this.state.hasQcMeta) {
      // iterate qc plots and set plot data
      // eslint-disable-next-line no-unused-vars
      for (const [key, plot] of Object.entries(plots.qc)) {
        this.setQcPlotData(plot);
      }
    }

    if (this.state.hasStageMeta) {
      // iterate stage plots and set plot data
      // eslint-disable-next-line no-unused-vars
      for (const [key, plot] of Object.entries(plots.stages)) {
        this.setStagePlotData(plot);
      }
    }

    // set state plots
    this.setState({
      plots: plots
    });
  }

  setQcPlotData = (plot) => {
    // parse x axis unit and map x axis values into an array
    let xAxisUnit = this.state.qcCutSummaryData[0].qc[0][`${plot.xAxisField}_unit`];
    // parse y axis unit and map y axis values into an array
    let yAxisUnit = this.state.qcCutSummaryData[0].qc[0][`${plot.yAxisField}_unit`];
    let plotData = [];
    this.state.qcCutSummaryData.forEach(qcsd => {
      let xData = qcsd.qc.map(r => {
        return r[plot.xAxisField];
      });
      let yData = qcsd.qc.map(r => {
        return r[plot.yAxisField];
      });
      plotData.push({
        x: xData,
        y: yData,
        name: `Part ${qcsd.sk.split('#')[1]}`
      });
    });

    // assing plot data
    Object.assign(plot, {
      data: plotData,
      xAxisUnit: xAxisUnit,
      yAxisUnit: yAxisUnit
    });
  }

  setStagePlotData = (plot) => {
    let plotData = {};

    this.state.parts.forEach(part => {
      plot.stages.forEach(stage => {
        if (plotData[stage] === undefined) {
          plotData[stage] = { x: [], y: [] };
        }
        // Check that given stage and stage field are in the part
        if (stage in part && plot.yAxisField in part[stage]) {
          plotData[stage].x.push(snakeCaseToCapitalizeCase(stage));
          plotData[stage].y.push(part[stage][plot.yAxisField]);
        }
      });
    });

    let data = [];
    plotData.green_stage.color = plot.colors[0];
    plotData.sintered_stage.color = plot.colors[1];
    data.push(plotData.green_stage);
    data.push(plotData.sintered_stage);
    // assing plot data
    Object.assign(plot, {
      data: data
    });
  }

  /**
   * @param plotGroup Group name for a given plot. Can be either 'qc' or 'stages'.
   * @param plotName  Name of plot in a given group.
   * @param type      The plot type, default is a line plot.
   * @returns         Plot component for the specified plot.
   */
  renderPlot = (plotGroup, plotName, type = 'line') => {
    let plot = this.state.plots[plotGroup][plotName];
    return (
      <Paper>
        <Plot
          type={type}
          title={plot.title}
          data={plot.data}
          xAxisTitle={plot.xAxisTitle}
          xAxisUnit={plot.xAxisUnit ? plot.xAxisUnit : undefined}
          yAxisTitle={plot.yAxisTitle}
          yAxisUnit={plot.yAxisUnit ? plot.yAxisUnit : undefined} />
      </Paper>
    );
  }

  computePercentError(actual, target, precision = this.state.precision) {
    return (Math.abs((actual - target) / target) * 100).toFixed(precision);
  }

  renderSummaryCardListItem = (primaryLabel, stats) => {
    let style = {
      inline: {
        display: 'inline'
      }
    };
    let renderStat = (stat) => {
      return (
        <Grid item key={stat.name}>
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
            style={style.inline}
          >
            {`${stat.name}: `}
          </Typography>
          {stat.value}
        </Grid>
      );
    };
    let statsLength = stats.length;
    let primaryLabelSplit = primaryLabel.split(':');
    return (
      <ListItemText
        primary={
          <Fragment>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              style={style.inline}
            >
              {`${primaryLabelSplit.length === 2 ? primaryLabelSplit[0] : ''}: `}
            </Typography>
            {primaryLabelSplit.length === 2 ? primaryLabelSplit[1] : ''}
          </Fragment>
        }
        primaryTypographyProps={{
          variant: 'subtitle1',
          color: 'textSecondary'
        }}
        secondary={
          statsLength === 0 ? (
            <Fragment />
          ) : (
            <Grid container direction='row' justify='center' alignItems='center'>
              {statsLength === 1 ? (
                renderStat(stats[0])
              ) : (
                stats.map((s, i) => {
                  return i === statsLength - 1 ? (
                    renderStat(s)
                  ) : (
                    <Fragment key={s.name}>
                      {renderStat(s)}
                      <Grid item style={{ marginLeft: 15, marginRight: 15 }}>|</Grid>
                    </Fragment>
                  );
                })
              )}
            </Grid>
          )
        }
        secondaryTypographyProps={{ variant: 'subtitle2' }}
      />
    );
  }

  renderSummaryCard = () => {
    let { mixingStep, highHeatStep } = this.state;
    if (mixingStep === undefined || highHeatStep === undefined) {
      return (
        <Fragment>
          <LinearProgress variant="query" />
        </Fragment>
      );
    }
    else {
      return (
        <Card style={{ boxShadow: ['none'], marginTop: 20 }}>
          {
            mixingStep.actual_abrasive_pct !== undefined && mixingStep.actual_bond_pct !== undefined && (
              <Fragment>
                <Typography variant='h6'>Mix Composition</Typography>
                <List component="nav" spacing={16}>
                  <Divider />
                  {
                    this.renderSummaryCardListItem(
                      `Abrasive: ${mixingStep.abrasive_type}`,
                      [
                        { name: 'Actual (%)', value: mixingStep.actual_abrasive_pct.toFixed(this.state.precision) },
                        { name: 'Target (%)', value: mixingStep.target_abrasive_pct.toFixed(this.state.precision) },
                        { name: '% Error', value: this.computePercentError(mixingStep.actual_abrasive_pct, mixingStep.target_abrasive_pct) }
                      ]
                    )
                  }
                  <Divider />
                  {
                    this.renderSummaryCardListItem(
                      `Bond: ${mixingStep.bond_type}`,
                      [
                        { name: 'Actual (%)', value: mixingStep.actual_bond_pct.toFixed(this.state.precision) },
                        { name: 'Target (%)', value: mixingStep.target_bond_pct.toFixed(this.state.precision) },
                        { name: '% Error', value: this.computePercentError(mixingStep.actual_bond_pct, mixingStep.target_bond_pct) }
                      ]
                    )
                  }
                  <Divider />
                  {
                    this.renderSummaryCardListItem(
                      'Density:',
                      [
                        { name: 'Actual (%)', value: mixingStep.actual_density.toFixed(this.state.precision) },
                        { name: 'Target (%)', value: mixingStep.target_density.toFixed(this.state.precision) },
                        { name: '% Error', value: this.computePercentError(mixingStep.actual_density, mixingStep.target_density) }
                      ]
                    )
                  }
                </List>
              </Fragment>
            )
          }
          {
            this.state.hasHighHeatStepMeta && (
              <Fragment>
                <Typography variant='h6'>Furance</Typography>
                <List component="nav">
                  <Divider />
                  {
                    this.renderSummaryCardListItem(
                      'Temperature:',
                      [
                        { name: 'Tmax (\u00B0C)', value: highHeatStep.t_max.toFixed(0) },
                        { name: 'Thold (\u00B0C)', value: highHeatStep.t_hold.toFixed(0) }
                      ]
                    )
                  }
                </List>
              </Fragment>
            )
          }
        </Card>
      );
    }
  }

  render() {
    let { hasMixingStepMeta, hasHighHeatStepMeta, hasQcMeta, hasStageMeta } = this.state;
    return (
      <div className={{ flexGrow: 1 }}>
        {
          this.state.isLoading ? (
            <LinearProgress />
          ) : (
            <Fragment>
              {
                hasMixingStepMeta || hasHighHeatStepMeta || hasQcMeta || hasStageMeta ? (
                  <div className={{ flexGrow: 1 }}>
                    <GridList cellHeight={'auto'} cols={3} style={{ padding: 15 }}>
                      <GridListTile>
                        {this.renderSummaryCard()}
                      </GridListTile>
                      {
                        this.state.hasStageMeta && (
                          [['massPlot', 'violin'], ['porosityPlot', 'violin']].map(p => {
                            return (
                              <GridListTile key={p[0]}>
                                {this.renderPlot('stages', p[0], p[1])}
                              </GridListTile>
                            );
                          })
                        )
                      }
                      {
                        this.state.hasQcMeta && (
                          ['normalForcePlot', 'specificPowerPlot', 'wearPlot'].map(p => {
                            return (
                              <GridListTile key={p}>
                                {this.renderPlot('qc', p)}
                              </GridListTile>
                            );
                          })
                        )
                      }
                    </GridList>
                  </div>
                ) : (
                  <Typography>Unable to retrieve mix step, highheat step, and part data.</Typography>
                )
              }
            </Fragment>
          )
        }
      </div>
    );
  }
}

OrderSummary.propTypes = {
  orderId: PropTypes.string.isRequired
};

export default withStyles(styles)(OrderSummary);
