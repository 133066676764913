import Grid from '@material-ui/core/Grid';
import {TextField, InputAdornment} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const styles = {
  root: {
    justify: 'center',
    padding: 15,
  },
};

class MixingStepOverviewComponent extends Component {

  render() {
    const {step} = this.props;
    return (
      <Fragment>
        <Grid container direction='row' justify='center' spacing={8}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Step Name'
              value={step.step_name || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              InputProps={{
                style: {color: '#303030'},
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Abrasive Material Type'
              value={step.abrasive_type || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Weight Abrasive %'
              type='number'
              value={step.target_abrasive_pct || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Tolerence of Abrasive'
              type='number'
              value={step.abrasive_tol || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Bond Material Type'
              value={step.bond_type || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Weight Bond %'
              type='number'
              value={step.target_bond_pct || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth
              label='Tolerence of Bond'
              type='number'
              value={step.bond_tol || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth
              label='Pycnometer Sample Weight'
              type='number'
              value={step.density_sample_mass || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth
              label='Pycnometer Sample Weight Tolerance'
              type='number'
              value={step.density_sample_mass_tolerance || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth
              label='Mix Time'
              type='number'
              value={step.mix_time || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth
              label='Target Mix Density'
              type='number'
              value={step.target_density || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">g/cc</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth
              label='Density Tolerance'
              type='number'
              value={step.density_tolerance || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">g/cc</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Fragment>

    );
  }
}

MixingStepOverviewComponent.propTypes = {
  step: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(MixingStepOverviewComponent);
