import React, { Component, Fragment } from 'react';
import copy from 'fast-copy';

import { Card, CardContent, Typography, Button } from '@material-ui/core/';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { NotFound } from './Errors';

import Layout from './Layout';
import ErrorsDialog from './Errors/errors-dialog';

import OrdersModule from './Orders/orders-module';
import ProcessStepsModule from './ProcessSteps/process-step-module';
import ScaleFactorAnalysisModule from './DataAnalysis/ScaleFactor/data-analysis-module';
import MaterialFormulationModule from './DataAnalysis/MaterialFormulation';
import HighheatStepOverviewModule from './Overviews/highheat-step-overview-module';
import MixingStepOverviewModule from './Overviews/mixing-step-overview-module';
import ProcessOrderOverviewModule from './Overviews/process-order-overview-module';
import PropTypes from 'prop-types';

import { authService } from '../service/auth.service';
import PrivateRoute from '../service/auth.private-route';

class App extends Component {
  constructor(props) {
    super(props);
    this.postWarningSnack = this.postWarningSnack.bind(this);
    this.postErrorSnack = this.postErrorSnack.bind(this);
    this.postSuccessSnack = this.postSuccessSnack.bind(this);
    this.postDefaultSnack = this.postDefaultSnack.bind(this);
    this.postSnack = this.postSnack.bind(this);
    this.reportError = this.reportError.bind(this);
  }

  componentDidMount() {
  }

  UNSAFE_componentWillMount() {
    this.setState({
      processSteps: [],
      processStepSteps: [],
      orders: [],
      other_apps: [],
      currentUser: {
        userId: 'a99wvzz' // TODO link into some user system
      },
      errors: []
    });
  }

  postWarningSnack(message) {
    this.postSnack(message, { variant: 'warning', autoHideDuration: 5000 });
  }

  postErrorSnack(message, autoHideDuration=5000) {
    this.postSnack(message, { variant: 'error', autoHideDuration: autoHideDuration });
  }

  postSuccessSnack(message) {
    this.postSnack(message, { variant: 'success', autoHideDuration: 5000 });
  }

  postDefaultSnack(message) {
    this.postSnack(message, { variant: 'default', autoHideDuration: 5000 });
  }

  postSnack(message, options) {
    this.props.enqueueSnackbar(message, options);
  }

  reportError(err) {
    const state = copy(this.state);
    state.errors = [...state.errors, err];
    this.setState(state);
  }
  render() {
    const props = this.props;

    const callbacks = {
        reportError: this.reportError,
        postWarningSnack: this.postWarningSnack,
        postErrorSnack: this.postErrorSnack,
        postSuccessSnack: this.postSuccessSnack,
        postDefaultSnack: this.postDefaultSnack,
        postSnack: this.postSnack
      }, state = this.state;

    return <Fragment>
      <ErrorsDialog open={this.state.errors && this.state.errors.length} errors={this.state.errors} />
      <HashRouter>
        <Layout
          {...props}
          {...callbacks}
          {...this.state}
          title='3M IoT Factory Automation'
        >
          <Switch>
            <Route exact path="/">
              <Card>
                <CardContent style={{textAlign: 'center'}}>
                  <Typography variant='display4' style={{ color: 'red', fontWeight: 'bold'}}>
												3M
                  </Typography>
                  <Typography variant='display3'>
												IIoT for <br/>
												Additive <br/>
												Manufacturing
                  </Typography>
                  {
                    authService.isAuthenticated() === false ?
                      <Button
                        style={{margin: 1, height: 40}}
                        variant='contained'
                        color='primary'
                        onClick={authService.login}>
                                Login
                      </Button>
                      : null
                  }
                </CardContent>
              </Card>
            </Route>
            <PrivateRoute path="/app/processSteps">
              <ProcessStepsModule style={{ textAlign: 'center' }}
                {...props}
                {...callbacks}
                {...state}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/orders">
              <OrdersModule style={{ textAlign: 'center' }}
                {...props}
                {...callbacks}
                {...state}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/data-analysis/scale-factor">
              <ScaleFactorAnalysisModule style={{ textAlign: 'center' }}
                {...props}
                {...callbacks}
                {...state}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/data-analysis/material-formulation">
              <MaterialFormulationModule style={{ textAlign: 'center' }}
                {...props}
                {...callbacks}
                {...state}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/highheatOverview/:id">
              <HighheatStepOverviewModule style={{ textAlign: 'center' }}
                {...callbacks}
                isSubComponent={false}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/mixingOverview/:id">
              <MixingStepOverviewModule style={{ textAlign: 'center' }}
                {...callbacks}
                isSubComponent={false}
              />
            </PrivateRoute>
            <PrivateRoute path="/app/processOrderOverview/:id">
              <ProcessOrderOverviewModule style={{ textAlign: 'center' }}
                {...callbacks}
              />
            </PrivateRoute>
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </HashRouter>
    </Fragment>;
  }
}

App.propTypes = {
  enqueueSnackbar: PropTypes.func,
};

export default App;
