// The purpose of the order entry page is to put in enough information to
// allow for an order to be placed and the optimization to be run.  Most fields are constants.

import React, { Fragment, Component } from 'react';
import { Typography } from '@material-ui/core/';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import OrderForm from './order-form';
import OrderTable from './order-table';
import PropTypes from 'prop-types';
import OrderSummary from './order-summary';
import OrderRoughness from './order-roughness';

const styles = {

};


class OrdersModule extends Component {

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }

  render() {
    const renderProps = this.props;
    const {
      match: { url }
    } = renderProps;

    return (

      <Switch>
        <Route exact path={url} render={() => {
          return (
            <div>
              <OrderTable
                {...renderProps}
              />
            </div>
          );
        }
        }
        />

        <Route path={`${url}/add/part-roughness`} render={
          () => {
            return (
              <Fragment>
                <OrderRoughness
                  {...renderProps}
                />
              </Fragment>
            );
          }
        } />

        <Route path={`${url}/add`} render={
          () => {
            return (
              <Fragment>
                <Typography align='center' variant='display1'>
                  Adding Order
                </Typography>

                <OrderForm
                  action='Add'
                  handleSaveButton={() => { }}//{this.addOrder}
                  {...renderProps}
                />
              </Fragment>
            );
          }
        } />

        <Route path={`${url}/edit/:orderId`} render={
          (props) => {
            const { match: { params: { orderId } } } = props;

            const orders = this.props.orders.filter((s) => {
              return s.id === orderId;
            });

            if (orders.length === 0) {
              return <Redirect to='/app/orders' />;
            }

            const order = orders[0];

            return (
              <Fragment>
                <Typography variant='display1'>
                                        Editing Order
                </Typography>

                <OrderForm
                  {...renderProps}
                  action='Edit'
                  handleSaveButton={() => { }}//{this.updateOrder}
                  handleDeleteIcon={this.deleteOrder}
                  handleSendIcon={this.submitOrder}
                  order={order}
                />
              </Fragment>
            );
          }
        } />

        <Route path={`${url}/summary/:orderId`} render={
          (props) => {
            const { match: { params: { orderId } } } = props;

            return (
              <div>
                <OrderSummary orderId={orderId} />
              </div>
            );
          }
        } />
      </Switch>
    );
  }
}

OrdersModule.propTypes = {
  match: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(OrdersModule);