import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { getTemplateProcessSteps, deleteTemplateProcessStep } from '../rest/ProcessStepRequests';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DeleteConfirmationDialog from '../Dialogs/delete-confirmation-dialog';
// import { Button, Fab, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core/';

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({

});

class HighHeatStepTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      deleteDialogOpen: false,
      deletePending: false,
      stepToDelete: null,
    };

    this.fetchHighHeatSteps = this.fetchHighHeatSteps.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  componentDidMount() {
    this.fetchHighHeatSteps();
  }

  async fetchHighHeatSteps() {
    this.setState({ isLoading: true });

    try {
      let resp = await getTemplateProcessSteps('highheat');

      if (resp.status === 200) {
        let steps = await resp.json();

        this.setState({
          data: steps,
          isLoading: false,
        });
      } else {
        // Consider other responses to be errors
        this.props.postErrorSnack('Error: Could not load process steps.');
        this.setState({ isLoading: false });
      }

    } catch (e) {
      this.props.postErrorSnack('Error: Could not connect to server.');
      this.setState({ isLoading: false });
    }
  }

  /**
* Closes the delete confirmation dialog.
*/
  closeDeleteDialog() {

    this.setState({
      deleteDialogOpen: false,
      orderToDelete: null
    });
  }

  /**
   * Opens the delete confirmation dialog.
   */
  openDeleteDialog(pk, tableMeta) {

    const stepToDelete = {
      pk: pk,
      name: tableMeta.rowData[0],
    };

    this.setState({
      deleteDialogOpen: true,
      stepToDelete: stepToDelete
    });
  }

  /**
   * Handles deleting the process step and
   * refreshing the table.
   */
  async handleDeleteConfirmed() {

    this.setState({ deletePending: true });

    try {

      const resp = await deleteTemplateProcessStep(this.state.stepToDelete.pk);

      if (resp.status === 200) {

        // Indicate success & reload the table
        this.props.postSuccessSnack('Process step deleted');
        this.fetchHighHeatSteps();

      } else {

        this.props.postErrorSnack('Error: Failed to delete process step');
      }

    } catch (e) {

      this.props.postErrorSnack('Error: Failed to delete process step');

    } finally {

      this.setState({ deletePending: false });
      this.closeDeleteDialog();
    }
  }

  render() {
    const columns = [
      {
        name: 'step_name',
        label: 'Step Name',
      },
      {
        name: 'profile.updated_at',
        label: 'Last Update',
      },
      {
        name: 't_max',
        label: 'Max Temperature',
      },
      {
        name: 't_hold',
        label: 'Time at Max Temperature',
      },
      {
        name: 'pk',
        label: 'Detailed View',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <Link to={`/app/highheatOverview/${value}`}>Details</Link>
            );
          }
        }
      },
      {
        name: 'pk',
        label: 'Delete',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <IconButton onClick={() => this.openDeleteDialog(value, tableMeta)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            );
          }
        }
      },
    ];

    const options = {
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isLoading ? 'Loading process steps...' : 'Sorry, no matching records found',
        }
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={'High Heat Process Steps'}
          data={this.state.data}
          columns={columns}
          options={options}
        />
        <DeleteConfirmationDialog
          open={this.state.deleteDialogOpen}
          handleClose={this.closeDeleteDialog}
          handleDeleteConfirmed={this.handleDeleteConfirmed}
          title={'Are you sure you want to delete this process step?'}
          text={this.state.stepToDelete ? this.state.stepToDelete.name : ''}
          deletePending={this.state.deletePending}
        />
      </React.Fragment>
    );
  }
}

HighHeatStepTable.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
};

export default withStyles(styles)(HighHeatStepTable);