import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, LinearProgress, Tooltip, Fab, Dialog, DialogContent, CircularProgress, Button } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import QrReader from 'react-qr-reader';
import * as PartsRequests from './../rest/PartsRequests';
import { styled } from '@material-ui/styles';
import PublishIcon from '@material-ui/icons/Publish';
import OrderRoughnessTable from './order-roguhness-table';
import OrderRoughnessForm from './order-roughness-form';

const SubmitIcon = styled(PublishIcon)({
  color: 'white',
});


class OrderRoughness extends Component {

  state = {
    qrResult: 'Scan Workpiece QR Label',
    partsToUpload: [],
    errors: {},
    isValidating: false,
    isUploading: false,
    isScanning: false,
    isManualEntryOpen: false
  }

  isPartValid = async (process_order_id, part_number) => {
    this.setState({ isValidating: true });
    let resp = await PartsRequests.getParts(process_order_id, false, part_number);
    let respJson = await resp.json();
    this.setState({ isValidating: false });
    if (resp.status !== 200 || Object.keys(respJson).length === 0) {
      this.props.postErrorSnack('Error: Failed to retrieve process order');
      return undefined;
    }
    return respJson;
  }

  isPartFound = (partMeta) => {
    let { partsToUpload } = this.state;
    return partsToUpload.some((p) => {
      return (p.process_order_id === partMeta.process_order_id) &&
        (p.part_number === partMeta.part_number) &&
        (p.q_prime === partMeta.q_prime);
    });
  }

  handleScan = async (data) => {
    if (data) {
      let state = {};
      let qrResult = JSON.parse(data);
      let { partsToUpload } = this.state;

      if (this.isPartFound(qrResult)) {
        this.props.postWarningSnack(`Already scanned workpiece ${qrResult.part_number} of ${qrResult.process_order_name}.`);
      }
      else {
        if (!this.state.isValidating) {
          let validPart = await this.isPartValid(qrResult.process_order_id, qrResult.part_number);
          console.log(validPart);
          if (validPart !== undefined) {
            let testCondition = undefined;
            if (validPart.qc !== undefined) {
              let targetQPrime = qrResult.q_prime;
              testCondition = validPart.qc.find(tc => {
                return tc.q_prime === targetQPrime;
              });
              if (testCondition !== undefined) {
                qrResult.ra = testCondition.ra;
                qrResult.rz = testCondition.rz;
              }
            }

            let { errors } = this.state;
            let error = {};
            error.ra = qrResult.ra === undefined ? true : false;
            error.rz = qrResult.rz === undefined ? true : false;
            errors[`${qrResult.process_order_id}#${qrResult.part_number}#${qrResult.q_prime}`] = error;
            partsToUpload.push(qrResult);
            Object.assign(state, {
              partsToUpload: partsToUpload,
              qrResult: 'Scan another workpiece.',
              errors: errors
            });
          }
          else {
            this.props.postErrorSnack('Not a valid workpiece!');
          }
        }
      }
      this.setState(state);
    }
  }

  handleError = err => {
    console.error(err);
  }

  doHandleTableInputChange = (e, rowIndex) => {
    let { partsToUpload, errors } = this.state;
    let textFieldName = e.target.id.split('#')[0];
    let partToUpload = partsToUpload[rowIndex];

    let value = parseFloat(e.target.value);
    let errorKey = `${partToUpload.process_order_id}#${partToUpload.part_number}#${partToUpload.q_prime}`;
    errors[errorKey][textFieldName] = (value === '') ? true : false;
    partToUpload[textFieldName] = value;
    partToUpload.unit = 'microns';
    this.setState({ partsToUpload: partsToUpload, errors: errors });
  }

  isInvalid = () => {
    // eslint-disable-next-line no-unused-vars
    for (let [workpieceKey, workpieceValue] of Object.entries(this.state.errors)) {
      // eslint-disable-next-line no-unused-vars
      for (let [workpieceValueKey, workpieceValueValue] of Object.entries(workpieceValue)) {
        if (workpieceValueValue) {
          return true;
        }
      }
    }
    return false;
  }

  handleUpload = async () => {
    this.setState({ isUploading: true });
    if (this.state.partsToUpload.length > 0) {
      if (!this.isInvalid()) {
        let resp = await PartsRequests.putRoughnesses({ roughnesses: this.state.partsToUpload });
        let roughnessMsg = this.state.partsToUpload.length > 1 ? 'roughnesses' : 'roughness';
        if (resp.status === 200) {
          this.props.postSuccessSnack(`Updated part ${roughnessMsg}!`);
        }
        else {
          this.props.postWarningSnack(`Unable to update part ${roughnessMsg}!`);
        }
      }
      else {
        this.props.postWarningSnack('Missing required fields!');
      }
    }
    else {
      this.props.postWarningSnack('No workpiece scanned!');
    }
    this.setState({ isUploading: false });
  }

  handleScanClick = () => {
    this.setState({ isScanning: true });
  }

  handleClose = () => {
    this.setState({
      isScanning: false
    });
  }

  handleManualEntryClick = async () => {
    this.setState({ isManualEntryOpen: true });
    // this.setState({ processOrders: await this.getProcessOrders()});
  }

  doHandleManualEntryDialogClose = () => {
    this.setState({ isManualEntryOpen: false });
  }

  doHandleManualEntrySubmit = (e) => {
    if (this.isPartFound(e)) {
      this.props.postWarningSnack(`Already scanned workpiece ${e.part_number} of ${e.process_order_name}.`);
    }
    else {
      let { partsToUpload, errors } = this.state;
      partsToUpload.push(Object.assign({}, e));

      let error = {};
      error.ra = e.ra === undefined ? true : false;
      error.rz = e.rz === undefined ? true : false;
      errors[`${e.process_order_id}#${e.part_number}#${e.q_prime}`] = error;
      this.setState({
        partsToUpload: partsToUpload,
        errors: errors
      });
    }
  }

  doHandleDeleteRow = (rowIndex) => {
    let { partsToUpload, errors } = this.state;
    let removedWorkpiece = partsToUpload.splice(rowIndex, 1)[0];
    let errorKey = `${removedWorkpiece.process_order_id}#${removedWorkpiece.part_number}#${removedWorkpiece.q_prime}`;
    delete errors[errorKey];
    this.setState({ partsToUpload: partsToUpload, errors: errors });
  }

  renderQrReader() {
    return (
      <Fragment>
        <Grid container justify='center' style={{ marginBottom: 15 }}>
          <Typography align='center' variant='h6'>
            {this.state.isValidating ? 'Validating workpiece, please wait...' : this.state.qrResult}
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleManualEntryClick}>
            Manual Entry
          </Button>
        </Grid>
        {this.state.isValidating ? <LinearProgress /> : <Fragment />}
        <QrReader
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
        />
      </Fragment>
    );
  }

  render = () => {
    return (
      <Fragment>
        <Paper style={{ padding: 15 }}>
          <Grid container direction='row' spacing={16} style={{ flexGrow: 1 }} align='center'>
            <Grid item xs={12} sm={3}>
              <Hidden xsDown>
                {this.renderQrReader()}
              </Hidden>
              <Hidden smUp>
                <Button variant="contained" color="primary" onClick={this.handleScanClick}>
                  Scan workpiece
                </Button>
                <Tooltip
                  title='Upload'
                  aria-label='Upload'
                  style={{
                    marginLeft: 15,
                    backgroundColor: '#00a152',
                  }}>
                  <Fab
                    onClick={() => { this.handleUpload(); }}>
                    <SubmitIcon />
                  </Fab>
                </Tooltip>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={9} style={{ maxHeight: '95vh', overflowY: 'auto' }}>
              {
                <OrderRoughnessTable
                  partsToUpload={this.state.partsToUpload}
                  errors={this.state.errors}
                  handleInputChange={this.doHandleTableInputChange}
                  handleDeleteRow={this.doHandleDeleteRow} />
              }
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Tooltip
              title='Upload'
              aria-label='Upload'
              style={{
                right: 30,
                bottom: 30,
                position: 'fixed',
                backgroundColor: '#00a152',
              }}>
              <Fab
                onClick={() => { this.handleUpload(); }}>
                <SubmitIcon />
              </Fab>
            </Tooltip>
          </Hidden>
        </Paper>
        <Dialog open={this.state.isUploading || this.state.isScanning} onClose={this.handleClose}
          PaperProps={this.state.isUploading ? {
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          } : {}}
        >
          <DialogContent
            style={{ overflow: 'hidden' }}
          >
            {
              this.state.isUploading ? <CircularProgress /> : <Fragment />
            }
            {
              this.state.isScanning ?
                this.renderQrReader() : <Fragment />
            }
          </DialogContent>
        </Dialog>
        <OrderRoughnessForm
          isOpen={this.state.isManualEntryOpen}
          handleClose={this.doHandleManualEntryDialogClose}
          handleSubmit={this.doHandleManualEntrySubmit} />
      </Fragment>
    );
  }
}

OrderRoughness.propTypes = {
  postSuccessSnack: PropTypes.func.isRequired,
  postWarningSnack: PropTypes.func.isRequired,
  postErrorSnack: PropTypes.func.isRequired
};

export default withWidth()(OrderRoughness);