import React, { Component, Fragment } from 'react';
import { Grid, TextField, FormLabel, InputAdornment, Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  error: {
    color: 'red'
  },
});

class DataAnalysisRightForm extends Component {

  componentDidMount() {
  }

  validate() {

    const errors = {};

    return errors;
  }

  render() {
    const { classes } = this.props;
    const { mixing_wheel, measurements } = this.props;
    const {calc_part_id, calc_part_od, calc_part_height, xy_change, z_change, good_bad_prediction} = measurements;
    let prediction_failed = z_change !== 0 && (z_change < 1 || xy_change < 1);

    return (
      <Fragment>
        {mixing_wheel}
        <Grid
          container
          direction='column'
          alignItems='stretch'
          spacing={16}
        >
          <Grid item xs={12}>
            <FormLabel>
              Green Dimensions
            </FormLabel>
            {prediction_failed ? 
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography classes={{ root: classes.error }} >Prediction Failed</Typography>
              </div> : <Fragment />}
          </Grid>
          <Grid item container xs={12} direction='row' alignItems='stretch' spacing={8}>
            <Grid item xs={6}>
              <TextField fullWidth
                id="calculated_part_id"
                label='Predicted Scale Factor X/Y'
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                disabled={true}
                error={prediction_failed}
                value={xy_change}
                // error={errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                id="calculated_part_id"
                label='Predicted Scale Factor Z'
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                disabled={true}
                error={prediction_failed}
                value={z_change}
                // error={errors.name ? true : false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="calculated_part_id"
              label='Green Part Inner Diameter'
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              disabled={true}
              error={prediction_failed}
              value={calc_part_id}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="calculated_part_od"
              label='Green Part Outer Diameter'
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              disabled={true}
              error={prediction_failed}
              value={calc_part_od}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="calculated_part_height"
              label='Green Part Height'
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              disabled={true}
              error={prediction_failed}
              value={calc_part_height}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="good_bad_prediction"
              label='Shape Deviation Estimation'
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
              error={prediction_failed}
              value={good_bad_prediction}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
DataAnalysisRightForm.propTypes = {
  classes: PropTypes.object.isRequired,
  measurements: PropTypes.object.isRequired,
  mixing_wheel: PropTypes.object,
  // action: PropTypes.string.isRequired,
  //handleSaveButton: PropTypes.func.isRequired,
};
export default withStyles(styles)(DataAnalysisRightForm);
