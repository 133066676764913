import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Typography, ListItem, Grid, TextField, IconButton, Divider, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class OrderRoughnessTable extends Component {
  state = {
    isDeleteAlertOpen: false
  }

  handleDeleteAlertClose = () => {
    this.setState({isDeleteAlertOpen: false});
  }

  handleDeleteAlertOpen = (rowIndex) => {
    this.setState({isDeleteAlertOpen: true, rowIndexToDelete: rowIndex});
  }

  renderDeleteAlert = () => {
    let { handleDeleteRow, partsToUpload } = this.props;
    let workpiece = partsToUpload[this.state.rowIndexToDelete];
    return (
      workpiece === undefined ? (
        <Fragment />
      ) : (
        <Dialog
          open={this.state.isDeleteAlertOpen}
          onClose={this.handleDeleteAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Workpiece?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to remove workpiece with Q' of ${workpiece.q_prime} of part ${workpiece.part_number} of process order ${workpiece.process_order_name}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteAlertClose} color="primary">
              No
            </Button>
            <Button onClick={() => {handleDeleteRow(this.state.rowIndexToDelete); this.handleDeleteAlertClose();}} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }

  renderPartInfoRow = (partMeta, partIndex) => {
    let style = {
      inputLabelProps: {
        shrink: true,
        style: { color: '#303030' },
      },
      inputProps: {
        style: { color: '#303030' }
      },
      inputPropsEndAdornment: (endAdornment) => {
        return Object.assign({
          endAdornment: endAdornment,
          inputProps: { min: 0, max: 999999999 }
        }, style.inputProps);
      }
    };
    let key = `${partMeta.process_order_id}#${partMeta.part_number}#${partMeta.q_prime}`;
    return (
      <Fragment key={key}>
        <ListItem>
          <Grid container direction='row' alignItems="center" spacing={16} style={{ flexGrow: 1 }}>
            <Grid item xs={12} md={7}>
              <Grid container direction='row' alignItems="center" spacing={16} style={{ flexGrow: 1 }}>
                <Grid item xs={6}>
                  <TextField fullWidth
                    id="outlined-basic"
                    label='Process Order Name'
                    value={partMeta.process_order_name || ''}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={style.inputLabelProps}
                    InputProps={style.inputProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth
                    id="outlined-basic"
                    label="Part Number"
                    value={partMeta.part_number || ''}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={style.inputLabelProps}
                    InputProps={style.inputProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth
                    id="outlined-basic"
                    label="Q'"
                    value={partMeta.q_prime || ''}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={style.inputLabelProps}
                    InputProps={style.inputProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container direction='row' alignItems="center" spacing={16} style={{ flexGrow: 1 }}>
                <Grid item xs={5}>
                  <TextField fullWidth
                    id={`ra#${key}`}
                    error={this.props.errors[key]['ra']}
                    value={partMeta.ra}
                    type="number"
                    label="RA"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={style.inputLabelProps}
                    InputProps={style.inputPropsEndAdornment(<InputAdornment position="end">microns</InputAdornment>)}
                    onChange={(e) => this.props.handleInputChange(e, partIndex)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField fullWidth
                    id={`rz#${key}`}
                    error={this.props.errors[key]['rz']}
                    value={partMeta.rz}
                    type="number"
                    label="RZ"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={style.inputLabelProps}
                    InputProps={style.inputPropsEndAdornment(<InputAdornment position="end">microns</InputAdornment>)}
                    data-foo={partIndex}
                    onChange={(e) => this.props.handleInputChange(e, partIndex)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton edge="end" aria-label="delete" onClick={() => this.handleDeleteAlertOpen(partIndex)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        {this.renderDeleteAlert()}
      </Fragment>
    );
  }

  render() {
    let { partsToUpload } = this.props;
    return (
      <Fragment>
        {
          partsToUpload.length > 0 ? (
            <List>
              {
                partsToUpload.map((pm, pi) => {
                  return this.renderPartInfoRow(pm, pi);
                })
              }
            </List>
          ) : (
            <Typography variant='h4'>No workpiece scanned.</Typography>
          )
        }
      </Fragment>
    );
  }
}

OrderRoughnessTable.propTypes = {
  partsToUpload: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired
};

export default OrderRoughnessTable;