import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider, withSnackbar } from 'notistack';

const SnackEnabledApp = withSnackbar(App);

render(
    <SnackbarProvider maxSnack={5}
        action={<CloseIcon />}
        anchorOrigin={{
            'vertical': 'top',
            'horizontal': 'right'
        }}
        preventDuplicate>
        <SnackEnabledApp leftMenuWidth={200} />
   </SnackbarProvider>,
  document.getElementById('root'))
