import React, { Component, Fragment } from 'react';
import { Typography, Grid, Button } from '@material-ui/core/';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { getTemplateProcessSteps } from '../rest/ProcessStepRequests';
import PropTypes from 'prop-types';

import {
  ProcessSteps,
  MIXING_STEP,
  PRINTING_STEP,
  LOWHEAT_STEP,
  DEPOWDERING_STEP,
  GREEN_BODY_MEAS_STEP,
  HIGH_HEAT_STEP,
  SINTERED_MEAS_STEP,
  CUT_MEAS_STEP,
  QA_MEAS_STEP
} from './data/process-steps';

import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import MixingStepModule from './mixing-step-module';
import MixingStepTable from './mixing-step-table';
import HighHeatStepForm from './highheat-step-form';
import HighHeatStepTable from './highheat-step-table';
import GenericStepForm from './process-step-form';

const humanReadableStepNames = {
  [MIXING_STEP]: 'Mixing',
  [PRINTING_STEP]: 'Printing',
  [LOWHEAT_STEP]: 'Low Heat',
  [DEPOWDERING_STEP]: 'De-powdering',
  [GREEN_BODY_MEAS_STEP]: 'Green Body Measuring',
  [HIGH_HEAT_STEP]: 'High Heat',
  [SINTERED_MEAS_STEP]: 'Sintered Measuring',
  [CUT_MEAS_STEP]: 'Cut Body Measuring',
  [QA_MEAS_STEP]: 'QA Body Measuring'
};


const styles = () => ({});

class ProcessStepsModule extends Component {

  constructor() {

    super();

    this.state = {
      action: 'View',
    };

    this.refreshProcessSteps = this.refreshProcessSteps.bind(this);
    this.handleAddHighHeatStep = this.handleAddHighHeatStep.bind(this);
    this.handleAddMixingStep = this.handleAddMixingStep.bind(this);
    this.handleOnHigHeatStepSaved = this.handleOnHigHeatStepSaved.bind(this);
  }

  componentDidMount() {
  }

  handleAddHighHeatStep() {
    this.props.history.push('/app/processSteps/add/highheat');
  }

  handleAddMixingStep() {
    this.props.history.push('/app/processSteps/add/mixing');
  }

  handleOnHigHeatStepSaved() {
    this.props.history.push('/app/processSteps/highheatsteptable');
  }

  findParametersForStep(stepType) {
    let selectedStep = ProcessSteps.filter((p) => {
      return p.stepType === stepType;
    });

    selectedStep = selectedStep[0];

    return selectedStep.parameters;
  }

  async refreshProcessSteps() {
    let resp = await getTemplateProcessSteps('mixing');
    let data = await resp.json();
    console.log(data);
  }

  render() {

    const renderProps = this.props;
    const {
      match: { url }
    } = renderProps;

    return (
      <Fragment>
        <Switch>
          <Route exact path={`${url}/highheatsteptable`} render={
            () => {
              return (
                <Fragment>
                  <Grid container style={{marginBottom: 10}}>
                    <Grid item xs={6}>
                      <Typography variant='display1'>
                                            High Heat Steps
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                      <Button
                        style={{margin: 1, height: 40}}
                        variant='contained'
                        color='primary'
                        onClick={this.handleAddHighHeatStep}>Create Highheat Step</Button>
                    </Grid>
                  </Grid>
                  <HighHeatStepTable

                    {...this.props}
                  />
                </Fragment>);
            }
          } />
          <Route exact path={`${url}/mixingsteptable`} render={
            () => {
              return (
                <Fragment>
                  <Grid container style={{marginBottom: 10}}>
                    <Grid item xs={6}>
                      <Typography variant='display1'>
                                            Mixing Steps
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                      <Button
                        style={{margin: 1, height: 40}}
                        variant='contained'
                        color='primary'
                        onClick={this.handleAddMixingStep}>Create Mixing Step</Button>
                    </Grid>
                  </Grid>
                  <MixingStepTable
                    {...this.props}
                  />
                </Fragment>);
            }
          } />
          <Route exact path={`${url}/add/mixing`} render={
            () => {
              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Adding {humanReadableStepNames[MIXING_STEP]} Step
                  </Typography>
                  <MixingStepModule
                    action='add'
                    stepType='mixing'
                    {...this.props}
                  />
                </Fragment>);
            }
          } />
          <Route exact path={`${url}/add/highheat`} render={
            () => {
              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Adding {humanReadableStepNames[HIGH_HEAT_STEP]} Step
                  </Typography>
                  <HighHeatStepForm
                    {...this.props}
                    action='add'
                    stepType='highheat'
                    onSaved={this.handleOnHigHeatStepSaved}
                    handleCloneIcon={() => { this.openCloneStepDialog(this.step); }}
                    handleSaveButton={this.addGenericStep}
                  />
                </Fragment>);
            }
          } />
          <Route exact path={`${url}/add/:stepType`} render={
            (props) => {
              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Adding {humanReadableStepNames[props.match.params.stepType]} Step
                  </Typography>
                  <GenericStepForm
                    action='add'
                    {...this.props}
                    stepType={props.match.params.stepType}
                    handleCloneIcon={() => { this.openCloneStepDialog(this.step); }}
                    handleSaveButton={this.addGenericStep}
                  />
                </Fragment>);
            }
          } />
          <Route path={`${url}/edit/mixing/:stepId`} render={
            (props) => {
              const match = props.match;
              const { stepId } = match.params;
              const steps = this.props.processSteps.filter((s) => {
                return s.id === stepId;
              });

              if (steps.length === 0) {

                if (this.props.processSteps.length) {

                  return <Redirect to='/app/process_steps' />;
                }

                return <Fragment></Fragment>;
              }

              const step = steps[0];

              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Editing {humanReadableStepNames[step.stepType]} Step
                  </Typography>
                  <MixingStepModule
                    action='edit'
                    stepType='mixing'
                    step={step}
                    handleSaveButton={this.updateMixingStep}
                    handleCloneIcon={() => { this.openCloneStepDialog(step); }}
                    handleDeleteIcon={this.deleteStep}
                    {...this.props}
                  />
                </Fragment>);
            }
          } />
          <Route path={`${url}/edit/highheat/:stepId`} render={
            (props) => {

              const match = props.match;
              const { stepId } = match.params;
              const steps = this.props.processSteps.filter((s) => {
                return s.id === stepId;
              });

              if (steps.length === 0) {

                if (this.props.processSteps.length) {

                  return <Redirect to='/app/process_steps' />;
                }

                return <Fragment></Fragment>;
              }

              const step = steps[0];

              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Editing {humanReadableStepNames[step.stepType]} Step
                  </Typography>
                  <HighHeatStepForm
                    action='edit'
                    stepType='highheat'
                    step={step}
                    onSaved={this.handleOnHigHeatStepSaved}
                    handleSaveButton={this.updateGenericStep}
                    handleCloneIcon={() => { this.openCloneStepDialog(step); }}
                    handleDeleteIcon={this.deleteStep}
                    {...this.props}
                  />
                </Fragment>);
            }
          } />
          <Route path={`${url}/edit/:stepType/:stepId`} render={
            (props) => {

              const match = props.match;
              const { stepId } = match.params;
              const steps = this.props.processSteps.filter((s) => {
                return s.id === stepId;
              });

              if (steps.length === 0) {
                return <Redirect to='/app/process_steps' />;
              }

              const step = steps[0];

              return (
                <Fragment>
                  <Typography variant='display1'>
                                            Editing {humanReadableStepNames[step.stepType]} Step
                  </Typography>
                  <GenericStepForm
                    action='edit'
                    {...this.props}
                    stepType={step.stepType}
                    step={step}
                    handleSaveButton={this.updateGenericStep}
                    handleCloneIcon={() => { this.openCloneStepDialog(this.step); }}
                    handleDeleteIcon={this.deleteStep}
                  />
                </Fragment>);
            }
          } />
        </Switch>
      </Fragment>

    );
  }
}

ProcessStepsModule.propTypes = {
  processSteps: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(ProcessStepsModule);
