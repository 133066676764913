
import Paper from '@material-ui/core/Paper';
import { Typography, Tooltip, Fab } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as ProcessStepRequests from '../rest/ProcessStepRequests';
import HighheatStepOverviewComponent from './highheat-step-overview-component';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirmationDialog from '../Dialogs/delete-confirmation-dialog';
import { deleteTemplateProcessStep } from '../rest/ProcessStepRequests';

const styles = {
  root: {
    justify: 'center',
    padding: 15,
  },
};

class HighheatStepOverviewModule extends Component {

  constructor() {

    super();
    this.state = {
      loading: true,
      step: {},
      deleteDialogOpen: false,
      deletePending: false,
    };

    this.fetchHighheatTemplate = this.fetchHighheatTemplate.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  componentDidMount() {
    this.fetchHighheatTemplate();
  }

  async fetchHighheatTemplate() {
    const id = this.props.match.params.id;
    try {
      let resp = await ProcessStepRequests.getTemplateByID(id);
      if (resp.status === 200) {
        let new_step = await resp.json();
        if (Object.entries(new_step).length === 0 && new_step.constructor === Object) {
          this.props.postErrorSnack('Highheat template did not exist.');
        } else if (!new_step.sk.endsWith('highheat')) {
          this.props.postErrorSnack('Retrieved template was not of type highheat.');
        } else {
          this.setState({ step: new_step });
          this.props.postSuccessSnack('Retrieved highheat template.');
        }
      } else {
        let err = await resp.json();

        this.props.postErrorSnack(`Error: Could not fetch highheat template (non 200 response) ${err}.`);
      }
    } catch (e) {
      this.props.postErrorSnack(`Error: Could not fetch highheat template ${e}.`);
    }
    this.setState({ loading: false });
  }

  /**
* Closes the delete confirmation dialog.
*/
  closeDeleteDialog() {

    this.setState({
      deleteDialogOpen: false
    });
  }

  /**
   * Opens the delete confirmation dialog.
   */
  openDeleteDialog() {

    this.setState({
      deleteDialogOpen: true,
    });
  }

  /**
   * Handles deleting the process order and
   * redirecting the user to the orders table.
   */
  async handleDeleteConfirmed() {

    this.setState({ deletePending: true });

    try {

      const resp = await deleteTemplateProcessStep(this.state.step.pk);

      if (resp.status === 200) {

        // Indicate success & go back to the table
        this.props.postSuccessSnack('Process step deleted');
        this.props.history.push('/app/processSteps/highheatsteptable');

      } else {

        this.props.postErrorSnack('Error: Failed to delete process step');
      }

    } catch (e) {

      this.props.postErrorSnack('Error: Failed to delete process step');

    } finally {

      this.setState({ deletePending: false });
      this.closeDeleteDialog();
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.loading ? <LinearProgress /> : <Fragment />}
        <Paper style={styles.root}>
          <Typography variant='h5'>Highheat Step</Typography>
          <br />
          <HighheatStepOverviewComponent
            {...this.props}
            step={this.state.step}
          />
        </Paper>
        <Tooltip
          title='Delete'
          aria-label='Delete'
          style={{
            left: 18,
            bottom: 40,
            position: 'fixed',
            backgroundColor: '#DC2700',
          }}>
          <Fab
            color="secondary"
            onClick={this.openDeleteDialog}>
            <DeleteIcon />
          </Fab>
        </Tooltip>
        <DeleteConfirmationDialog
          open={this.state.deleteDialogOpen}
          handleClose={this.closeDeleteDialog}
          handleDeleteConfirmed={this.handleDeleteConfirmed}
          title={'Are you sure you want to delete this process step?'}
          text={this.state.step.step_name ? this.state.step.step_name : ''}
          deletePending={this.state.deletePending}
        />
      </Fragment>

    );
  }
}

HighheatStepOverviewModule.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSubComponent: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(HighheatStepOverviewModule);
