import {Grid, Paper, Typography, Button, TextField, MenuItem, FormControl, InputLabel, Select, Input, Chip} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import * as SageMakerRequests from './../../rest/SageMakerRequests';
import copy from 'fast-copy';
import PropTypes from 'prop-types';

import { getProcessOrders } from '../../rest/ProcessOrderRequests';
import { getParts } from '../../rest/PartsRequests';
import Plot from '../../Plots/plot';

const styles = {
  textField: {
    width: '85%',
    marginTop: 10,
    marginBottom: 10
  },
  miniGrid: {
    textAlign: 'center'
  },
  buttonGrid: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  typography: {
    marginBottom: 20
  },
  formControl: {
    width: '100%'
  },
  formControlPaper: {
    padding: '0px 20px 0px 20px',
    marginBottom: 20
  },
  processOrdersClass: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  processOrderClass: {
    margin: 2,
  },
  noLabel: {
    marginTop: 10,
  },
  paper: {
    padding: '20px 20px 20px 20px',
    marginBottom: 20
  },
  error: {
    color: 'red'
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MaterialFormulation extends Component {

  constructor() {
    super();

    this.state = {
      result: {
        gritSizeB: 0,
        compWeightPercentage: 0
      },
      processOrders: [],
      selectedProcessOrders: [],
      power: {
        slope: 0,
        priority: 1
      },
      wheelWear: {
        slope: 0,
        priority: 1
      },
      gRatio: {
        slope: 0,
        priority: 1,
      }
    };

    this.updateState = this.updateState.bind(this);
    this.updateProcessOrdersState = this.updateProcessOrdersState.bind(this);
    this.extractQCData = this.extractQCData.bind(this);
    this.runRecommendation = this.runRecommendation.bind(this);
  }

  componentDidMount() {
    this.fetchProcessOrders();
  }

  async fetchProcessOrders() {
    const state = copy(this.state);
    try {
      let resp = await getProcessOrders();

      if (resp.status === 200) {
        let orders = await resp.json();
        state.processOrders = orders.filter((po) => po.status === 'COMPLETE');
        this.setState(state);
      } else {
        // Consider other responses to be errors
        this.props.postErrorSnack('Error: Could not load process orders.');
      }
    } catch (e) {
      this.props.postErrorSnack('Error: Could not connect to server.');
    }
  }

  async runRecommendation() {
    const state = copy(this.state);
    let resp = await SageMakerRequests.getMaterialFormulation(this.state.power.slope, this.state.wheelWear.slope, this.state.gRatio.slope);
    if (resp.status === 200) {
      let result = await resp.json();
      state.result.gritSizeB = result['grit_size_b'];
      state.result.compWeightPercentage = result['comp_weight_percentage'];
      this.setState(state);
    }
  }

  updateState(event) {
    const state = copy(this.state);
    const [parent, child] = event.target.id.split('.');
    state[parent][child] = event.target.value;
    this.setState(state);
  }

  getGraphData(process_order, yaxis) {
    let resultGraphData = [];
    process_order.qcData.forEach((qcData) => {
      if (qcData.qc) {
        let graphData = qcData.qc.map((qc) => {
          return {
            xAxis: qc.q_prime,
            yAxis: qc[yaxis]
          };
        }).reduce((accum, curr) => {
          accum.x.push(curr.xAxis);
          accum.y.push(curr.yAxis);
          return accum;
        },
        {
          name: process_order.name + ' ' + qcData.sk,
          x: [],
          y: []
        });
        resultGraphData.push(graphData);
      }
    });
    return resultGraphData;
  }

  extractQCData(yAxis) {
    let processOrdersWithQCData = this.state.selectedProcessOrders.filter((po) => {
      if( po.qcData && po.qcData.length > 0 ) {
        let check = false;
        po.qcData.forEach((qcData) => {
          if (qcData.qc && qcData.qc.length > 0) {
            check = true;
          }
        });
        if (check) {
          return po;
        }
      }
      return null;
    });

    let allGraphData = processOrdersWithQCData.map(po => {
      return this.getGraphData(po, yAxis);
    });

    return allGraphData.flat(1);
  }

  async updateProcessOrdersState(event) {
    const state = copy(this.state);

    // Get dictionary of process orders by process_order_id
    let countDict = event.target.value.reduce((accum, currVal) => {
      if (!accum[currVal.process_order_id]) {
        accum[currVal.process_order_id] = [currVal];
      } else {
        accum[currVal.process_order_id].push(currVal);
      }
      return accum;
    }, {});

    // Use dictionary of process orders by id, to filter out any that occur more than once
    // (means they were suppoesed to be removed)
    let filtered = event.target.value.filter((val) => {
      if (countDict[val.process_order_id].length === 1) {
        return val;
      }
      return null;
    });

    // Loop through
    for(let i = 0; i < filtered.length; i++) {
      if (!filtered[i].poData) {
        let resp = await getParts(filtered[i].process_order_id);
        if (resp.status === 200) {
          filtered[i].qcData = await resp.json();
        }
      }
    }

    // set state to filtered qc data
    state.selectedProcessOrders = filtered;
    this.setState(state);
  }

  validate(state) {
    const validations = {
      'power.slope': {
        type: 'number',
        name: 'Power Slope',
        min: -999999999,
        max: 999999999,
      },
      'power.priority': {
        type: 'number',
        name: 'Power Priority',
        min: 1,
        max: 999999999
      },
      'wheelWear.slope': {
        type: 'number',
        name: 'Wheel Wear Slope',
        min: -999999999,
        max: 999999999,
      },
      'wheelWear.priority': {
        type: 'number',
        name: 'Wheel Wear Priority',
        min: 1,
        max: 999999999
      },
      'gRatio.slope': {
        type: 'number',
        name: 'G Ratio Slope',
        min: -999999999,
        max: 999999999,
      },
      'gRatio.priority': {
        type: 'number',
        name: 'G Ratio Priority',
        min: 1,
        max: 999999999
      },
    };

    const errors = {};

    Object.keys(validations).forEach((field) => {
      const [parent, child] = field.split('.');
      switch(validations[field].type) {
      case 'number':

        if (validations[field].min && state[parent][child] < validations[field].min) {
          errors[field] = `${validations[field].name} must be ${validations[field].min} or higher.`;
        }

        if (validations[field].max && state[parent][child] > validations[field].max) {
          errors[field] = `${validations[field].name} must be ${validations[field].max} or lower.`;
        }

        if (typeof state[parent][child] === 'undefined' || state[parent][child] === '' || isNaN(state[parent][child])) {
          errors[field] = `${validations[field].name} must have a numeric value.`;
        }
        break;
      case 'string':
        if (typeof state[parent][child] === 'undefined' || state[parent][child].length < validations[field].minLength) {
          errors[field] = `${validations[field].name} must be at least ${validations[field].minLength} characters long.`;
        }
        break;
      case 'select':
        validations[field].cannot_be.forEach((element) => {
          if (typeof state[parent][child] === 'undefined' || element === state[parent][child]){
            errors[field] = `${validations[field].name} is required.`;
          }
        });
        break;
      default:
        break;
      }
    });

    return errors;
  }

  render() {
    const { classes } = this.props;
    const { power, wheelWear, gRatio, processOrders, selectedProcessOrders } = this.state;
    const specificPowerGraph = this.extractQCData('specific_power');
    const wheelWearGraph = this.extractQCData('wear');
    const gRatioGraph = this.extractQCData('g_ratio');
    const errors = this.validate(this.state);

    return (
      <Fragment>
        <Grid container justify='space-evenly' direction='row' alignItems="center">
          <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Typography variant='h4' className={classes.typography}>
                Material Formulation Recommender
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FormControl className={classes.formControl}>
                <InputLabel id="process-orders-label">Process Orders</InputLabel>
                <Select
                  id="multiple-process-orders"
                  multiple
                  value={selectedProcessOrders}
                  onChange={this.updateProcessOrdersState}
                  input={<Input id="select-multiple-processOrders" />}
                  style={{width: '100%'}}
                  renderValue={selected => (
                    <div className={classes.processOrdersClass}>
                      {selected.map(value => (
                        <Chip key={value.process_order_id} label={value.name} className={classes.processOrderClass} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {processOrders.map(processOrder => (
                    <MenuItem key={processOrder.process_order_id} value={processOrder}>
                      {processOrder.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              { wheelWearGraph.length > 0 &&
                <Grid container justify='space-evenly' direction='row' alignItems="center">
                  <Grid item xs={4} className={classes.miniGrid}>
                    <Plot type='line' title='Power' xAxisTitle='X-Axis' xAxisUnit='((mm^3/sec)/mm)' yAxisTitle='Power' yAxisUnit=''  data={specificPowerGraph} />
                  </Grid>
                  <Grid item xs={4} className={classes.miniGrid}>
                    <Plot type='line' title='Wheel Wear' xAxisTitle='X-Axis' xAxisUnit='((mm^3/sec)/mm)' yAxisTitle='Wear' yAxisUnit=''  data={wheelWearGraph} />
                  </Grid>
                  <Grid item xs={4} className={classes.miniGrid}>
                    <Plot type='line' title='G Ratio' xAxisTitle='X-Axis' xAxisUnit='((mm^3/sec)/mm)' yAxisTitle='G Ratio' yAxisUnit=''  data={gRatioGraph} />
                  </Grid>
                </Grid>
              }
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container justify='space-evenly' direction='row' alignItems="center">
                <Grid item xs={4} className={classes.miniGrid}>
                  <Typography variant='h5'>Power</Typography>
                  <TextField
                    id="power.slope"
                    label='Slope'
                    type='number'
                    className={classes.textField}
                    value={power.slope}
                    error={errors['power.slope'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['power.slope'] ? <Typography classes={{ root: classes.error }} >{errors['power.slope']}</Typography> : <Fragment />}
                  <TextField
                    id="power.priority"
                    label='Priority'
                    type='number'
                    className={classes.textField}
                    value={power.priority}
                    error={errors['power.priority'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['power.priority'] ? <Typography classes={{ root: classes.error }} >{errors['power.priority']}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={4} className={classes.miniGrid}>
                  <Typography variant='h5'>Wheel Wear</Typography>
                  <TextField
                    id="wheelWear.slope"
                    label='Slope'
                    type='number'
                    className={classes.textField}
                    value={wheelWear.slope}
                    error={errors['wheelWear.slope'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['wheelWear.slope'] ? <Typography classes={{ root: classes.error }} >{errors['wheelWear.slope']}</Typography> : <Fragment />}
                  <TextField
                    id="wheelWear.priority"
                    label='Priority'
                    type='number'
                    className={classes.textField}
                    value={wheelWear.priority}
                    error={errors['wheelWear.priority'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['wheelWear.priority'] ? <Typography classes={{ root: classes.error }} >{errors['wheelWear.priority']}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={4} className={classes.miniGrid}>
                  <Typography variant='h5'>G Ratio</Typography>
                  <TextField
                    id="gRatio.slope"
                    label='Slope'
                    type='number'
                    className={classes.textField}
                    value={gRatio.slope}
                    error={errors['gRatio.slope'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['gRatio.slope'] ? <Typography classes={{ root: classes.error }} >{errors['gRatio.slope']}</Typography> : <Fragment />}
                  <TextField
                    id="gRatio.priority"
                    label='Priority'
                    type='number'
                    className={classes.textField}
                    value={gRatio.priority}
                    error={errors['gRatio.priority'] ? true : false}
                    onChange={(ev) => { this.updateState(ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    // error={errors.name ? true : false} margin="normal"
                  />
                  {errors['gRatio.priority'] ? <Typography classes={{ root: classes.error }} >{errors['gRatio.priority']}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={12} className={classes.buttonGrid}>
                  <Button
                    style={{margin: 1, height: 40}}
                    variant='contained'
                    color='primary'
                    onClick={this.runRecommendation}>
                      Run Recommender
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Grid container justify='space-evenly' direction='row' alignItems="center">
                <Grid item xs={6} className={classes.miniGrid}>
                  <Typography variant='h5'>Grit Size</Typography>
                  <Typography variant='h6'>{this.state.result.gritSizeB.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.miniGrid}>
                  <Typography variant='h5'>Comparison Wt%</Typography>
                  <Typography variant='h6'>{this.state.result.compWeightPercentage.toFixed(2)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

MaterialFormulation.propTypes = {
  classes: PropTypes.object.isRequired,
  processOrders: PropTypes.object,
  postErrorSnack: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(MaterialFormulation);