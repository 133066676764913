/**
 *  Plot component is a wrapper around a Plotly plot and requires the following props:
 *      - type: Determines the type of plot. The allowed types are:
 *          - line: Line plot.
 *          - violin: Violin plot.
 *      - width: Width of the plot. Defaults to 100%.
 *      - height: Height of the plot. Defaults to 100%.
 *      - title: Title of the plot.
 *      - xAxisUnit: X axis unit.
 *      - xAxisTitle: X axis title.
 *      - yAxisUnit: Y axis unit.
 *      - yAxisTitle: Y axis title.
 *      - data: An array of plot data.
 *          - Ex. data = [
 *              {name: 'Part 1', x: [0.75, 1, 1.25], y: [20.40, 28.26, 34.59]},
 *              {name: 'Part 2', x: [0.75, 1, 1.25], y: [21.34, 28.51, 34.20]}
 *          ]
 *          NOTE: 'name' is optional, but x and y data are required.
 *
 *  @author José Araujo <jaraujo@mmm.com>
 */

import React, { Component } from 'react';
import ReactPlot from 'react-plotly.js';
import { LinearProgress } from '@material-ui/core';


export default class Plot extends Component {
  isEmpty() {
    const { data } = this.props;
    return data === undefined || data.length === 0 ? true : false;
  }

  getLayout() {
    const { type, title, xAxisUnit, xAxisTitle, yAxisUnit, yAxisTitle } = this.props;
    let defaultLayout = {
      title: title,
      xaxis: {
        title: `${xAxisTitle}  ${xAxisUnit !== undefined ? `(${xAxisUnit})` : ''}`
      },
      yaxis: {
        title: `${yAxisTitle} ${yAxisUnit !== undefined ? `(${yAxisUnit})` : ''}`
      },
      autosize: true
    };

    switch (type) {
    case 'violin':
      return Object.assign(defaultLayout, {
        yaxis: {
          zeroline: false
        },
        violinmode: 'group'
      });
    case 'line':
    default:
      return defaultLayout;
    }
  }

  getData() {
    const { type } = this.props;
    switch (type) {
    case 'violin':
      return this.getViolinData();
    case 'line':
    default:
      return this.getLineData();
    }
  }

    getLineData = () => {
      const { data } = this.props;
      return data;
    }


    getViolinData() {
      const { data } = this.props;
      return data.map(d => {
        return {
          type: 'violin',
          x: d.x,
          y: d.y,
          points: 'none',
          box: {
            visible: true
          },
          line: {
            color: d.color,
          },
          meanline: {
            visible: true
          },
          name: d.x[0]
        };
      });
    }

    getSize() {
      const { width, height } = this.props;
      return {
        width: width === undefined ? '100%' : width,
        height: height === undefined ? '100%' : height
      };
    }

    render() {
      return (
        this.isEmpty() === true ? (
          <div>
            <LinearProgress variant="query" />
            <ReactPlot
              layout={this.getLayout()}
              style={this.getSize()}
            />
          </div>
        ) : (
          <ReactPlot
            data={this.getData()}
            layout={this.getLayout()}
            style={this.getSize()}
          />
        )
      );
    }
}
