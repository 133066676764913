
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DataAnalysisLeftForm from './data-analysis-left-form';
import DataAnalysisRightForm from './data-analysis-right-form';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import * as SageMakerRequests from './../../rest/SageMakerRequests';
import copy from 'fast-copy';
import PropTypes from 'prop-types';

const styles = {
  root: {
    justify: 'center',
    padding: 15,
    marginTop: 15,
  },
  AddFab: {
    right: 28,
    bottom: 40,
    position: 'fixed'
  },
  AddIconLink : {
    textDecoration: 'none',
    color: 'white',
    fontSize: '150%'
  }
};


class DataAnalysisModule extends Component {

  constructor() {

    super();

    this.state = {
      left_panel: {
        selected_mixing_value: 0,
        part_id: 0,
        part_od: 0,
        part_height: 0,
        errors: {},
      },
      right_panel: {
        calc_part_id: 0,
        calc_part_od: 0,
        calc_part_height: 0,
        xy_change: 0,
        z_change: 0,
        good_bad_prediction: 0,
      },
    };
    this.fetchScaleFactor = this.fetchScaleFactor.bind(this);
  }
  async fetchScaleFactor() {
    const {part_id, part_od, part_height, selected_mixing_value, errors} = this.state.left_panel;
    console.log('errors', errors);
    if(Object.keys(errors).length) {
      this.props.postErrorSnack('All fields required.');
      return;
    }
    try {
      let resp = await SageMakerRequests.getScaleFactor(part_od, part_id, part_height, selected_mixing_value);
      if (resp.status === 200) {
        const state = copy(this.state);
        let measurements = await resp.json();
        state.right_panel.calc_part_id = measurements.part_id;
        state.right_panel.calc_part_od = measurements.part_od;
        state.right_panel.calc_part_height = measurements.part_height;
        state.right_panel.xy_change = measurements.xy_factor;
        state.right_panel.z_change = measurements.z_factor;
        state.right_panel.good_bad_prediction = measurements.prediction;
        this.setState(state);
        if (measurements.z_factor < 1 || measurements.xy_factor < 1) {
          this.props.postWarningSnack('Prediction failed for given inputs.');
        } else {
          this.props.postSuccessSnack('Retrieved prediction.');
        }
      } else {
        this.props.postErrorSnack('Error: Could not fetch scale factor (non 200 response).');
      }
    } catch(e) {
      this.props.postErrorSnack('Error: Could not fetch scale factor.');
    }
  }

  UNSAFE_componentWillMount() {

  }

  componentDidMount() {

  }

  render() {

    // const renderProps = this.props;
    // const {
    //   match: { url }
    // } = renderProps;
    return (
      <Fragment>
        <Grid container justify='space-evenly' direction='row' alignItems="center">
          <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Typography variant='h4'>
                Scale Factor Predictions
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Paper style={styles.root}>
              <DataAnalysisLeftForm
                {...this.props}
                {...this.state}
              />
            </Paper>
          </Grid>
          <Grid item xs={1}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button fullWidth
                variant='contained'
                color='primary'
                onClick={this.fetchScaleFactor}
              >
                                Calculate
              </Button>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Paper style={styles.root}>
              <DataAnalysisRightForm
                measurements={this.state.right_panel}
                {...this.props}
              />
            </Paper>
          </Grid>
        </Grid>
      </Fragment>

    );
  }
}

DataAnalysisModule.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  postWarningSnack: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(DataAnalysisModule);
