import React, { Component, Fragment } from 'react';
import { ProcessSteps } from './data/process-steps';
import { Fab, Grid, IconButton, InputAdornment, InputLabel, MenuItem, TextField, Select, Tooltip, Typography } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloneIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import copy from 'fast-copy';
import { isNumber } from 'util';

const styles = theme => ({
  error: {
    color: 'red',
    fontSize: '70%'
  },
  input: {
    textAlign: 'right',
    minWidth: 120
  },
  parameterField: {
    minWidth: 300
  },
  DeleteFab: {
    right: 18,
    bottom: 120,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  DeleteIconLink: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '150%'
  },
  SaveFab: {
    right: 18,
    bottom: 40,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  CloneFab: {
    right: 18,
    bottom: 200,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  deleted: {
    textAlign: 'center',
    fontFamily: 'Tahoma',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#CC0000'
  }
});

const saveMessage = 'Save Process Step';
const cloneMessage = 'Clone This Process Step';
const deleteMessage = 'Delete Process Step';

class GenericStepForm extends Component {
  constructor() {
    super();
    // bind methods to this component (so that they can be called at this.METHOD_NAME)
    this.findParametersForStep = this.findParametersForStep.bind(this);
    this.updateStepField = this.updateStepField.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.handleCloneIcon = this.handleCloneIcon.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const state = copy(this.state);
    window.process_step_history_open = state.open = !window.process_step_history_open || false;
    this.setState(state);
  }

  UNSAFE_componentWillMount() {
    const state = {
      step: {
        name: '',
        version: '1.0.0',
        ...this.props.step,
        stepType: this.props.stepType
      }
    };
    state.parameterList = this.findParametersForStep(this.props.stepType);
    state.parameterList.forEach((p) => {
      if (state.step[p.tag] === undefined)
        state.step[p.tag] = p.typical ? p.typical : '';
    });
    this.setState(state);
  }

  handleCloneIcon(step) {
    this.props.handleCloneIcon(step);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.step) {

      const state = copy(this.state);
      state.step = props.step;
      state.stepType = props.stepType;
      state.parameterList = this.findParametersForStep(this.props.stepType);
      state.parameterList.forEach((p) => {
        state.step[p.tag] = p.typical ? p.typical : '';
      });
      this.setState(state);
    }
  }

  findParametersForStep(stepType) {
    if (!stepType)
      return [];
    let selectedStep = ProcessSteps.filter((p) => {
      return p.stepType === stepType;
    });
    if (!selectedStep || selectedStep.length === 0)
      return [];
    selectedStep = selectedStep[0];
    return selectedStep.parameters;
  }

  updateStepField(name, event) {
    const state = copy(this.state);
    state.step[name] = event.target.value;
    this.setState(state);
  }

  validate(step) {
    const errors = {};
    if (!step.name) {
      errors.name = 'Name is required';

    } else if (step.name.length < 4) {

      errors.name = 'Name should be at least 4 characters';
    }

    if (this.props.processSteps) {
      const matchesByName = this.props.processSteps.filter((s) => {
        return s.name === step.name;
      });
      if (this.props.action === 'add' && matchesByName.length && matchesByName[0].id !== step.id)
        errors.name = 'Name is already used in another process step.';
    }

    // check each field against its min/max
    this.state.parameterList.forEach((p) => {
      if (isNumber(p.min) && isNumber(p.max)) {
        if (step[p.tag] < p.min || step[p.tag] > p.max) {
          errors[p.tag] = `${p.parameter} must be between ${p.min} and ${p.max}`;
        }
      }
    });
    return errors;
  }
  render() {
    const { classes } = this.props;
    const { step } = this.state;
    const errors = this.validate(step);
    return (
      <Fragment>
        <Grid key='row0' container direction='row'>
          <Grid key='col1' item xs={6}>

            <TextField styles={{ paddingLeft: 40, marginLeft: 40, minWidth: 200 }}
              className={classes.parameterField}
              error={(errors['name']) ? true : false}
              label={'Step Name'}
              value={step.name}
              onChange={(ev) => { this.updateStepField('name', ev); }}
              margin="normal"
            />
            {errors.name ? <Typography classes={{ root: classes.error }} >{errors.name}</Typography> : <Fragment />}
            <br />
            {step.deleted_at
              ? <div className={classes.deleted} >&nbsp;DELETED&nbsp;</div>
              : <Fragment />}
          </Grid>
          <Grid key='col2' item xs={2}>
            <TextField
              label='Version'
              className={classes.textField}
              disabled
              value={step.version}
            />
          </Grid>
          <Grid key='col3' item xs={1}>
            <Tooltip title="Navigate to older and newer versions of this process">
              <IconButton onClick={() => this.toggleOpen()}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid key='col4' item xs={3}>
            <InputLabel styles={styles.InputLabel}>Update version level</InputLabel>
            <br />
            <Tooltip title="This controls how this step's next version will be derived.">
              <Select
                label='Upon Update, increment version at '
                className={classes.select}
                value={step.level || 1}
                onChange={(ev) => { this.updateStepField('level', ev); }}
                inputProps={{
                  name: 'level',
                  id: 'level-id',
                }} >
                <MenuItem key='level0' value={0}>Major</MenuItem>
                <MenuItem key='level1' value={1}>Minor</MenuItem>
                <MenuItem key='level2' value={2}>Patch</MenuItem>
              </Select>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid key='row1' container direction='row'>
          <Grid key='col1' item xs={12}>
            <div>
              {this.state.parameterList.map((p) => {
                return (
                  <Grid container key={p.tag}>
                    <Grid>
                      <Tooltip title={p.parameter} aria-label={p.parameter}>
                        <TextField
                          className={classes.parameterField}
                          error={(errors[p.tag]) ? true : false}
                          label={p.parameter}
                          value={step[p.tag]}
                          type={p.type ? p.type : 'number'}
                          onChange={(ev) => { this.updateStepField(p.tag, ev); }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{p.uom}</InputAdornment>,
                            classes: { input: classes.input }
                          }}
                          margin="normal"
                        />
                      </Tooltip>
                      {errors[p.tag] ? <Typography classes={{ root: classes.error }} >{errors[p.tag]}</Typography> : <Fragment />}
                    </Grid>
                  </Grid>);
              })}

              {this.props.handleDeleteIcon ?
                <Tooltip key='tt1' title={deleteMessage} aria-label={deleteMessage}>
                  <Fab color="secondary" className={classes.DeleteFab}>
                    <DeleteIcon style={styles.DeleteIcon}
                      onClick={() => { this.props.handleDeleteIcon(step); }}
                    />
                  </Fab>
                </Tooltip> : <Fragment />}

              <Tooltip key='tt2' title={cloneMessage} aria-label={cloneMessage}>
                <Fab color="secondary" className={classes.CloneFab}>
                  <CloneIcon style={styles.CloneIcon}
                    disabled={Object.keys(errors).length > 0}
                    onClick={() => { this.handleCloneIcon(step); }}
                  />
                </Fab>
              </Tooltip>

              <Tooltip key='tt3' title={saveMessage} aria-label={saveMessage}>
                <Fab color="secondary" className={classes.SaveFab}>
                  <SaveIcon style={styles.SaveIcon}
                    disabled={Object.keys(errors).length > 0}
                    onClick={() => { this.handleSaveButton(step); }}
                  />
                </Fab>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
GenericStepForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSaveButton: PropTypes.func.isRequired,
  handleDeleteIcon: PropTypes.func,
  handleCloneIcon: PropTypes.func,
  processSteps: PropTypes.array,
  action: PropTypes.string,
  step: PropTypes.object,
  stepType: PropTypes.string
};
export default compose(
  withRouter,
  withStyles(styles)
)(GenericStepForm);