import React, { Component, Fragment } from 'react';
import { Grid, TextField, Typography, FormLabel, InputAdornment } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import copy from 'fast-copy';
import * as ProcStepRequests from './../../rest/ProcessStepRequests';

const styles = ({
  error: {
    color: 'red'
  },
});

class DataAnalysisLeftForm extends Component {

  constructor() {

    super();

    this.state = {
      part_id: 0,
      part_od: 0,
      part_height: 0,
      mixing_steps: [],
      selected_mixing_value: 0,
      mixing_wheel: [],
    };
    this.handleCreateNewMixingStep = this.handleCreateNewMixingStep.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.fetchMixingSteps = this.fetchMixingSteps.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  componentDidMount() {
    const state = copy(this.state);
    state.mixing_wheel = <LinearProgress />;
    this.setState(state);
    this.fetchMixingSteps();
  }

  async fetchMixingSteps() {

    let menuItems = await this.fetchProcessSteps('mixing');

    this.setState({
      mixing_steps: menuItems,
      mixing_wheel: [],
    });

    if (menuItems) {
      this.props.postSuccessSnack(`Just loaded ${menuItems.length} mixing steps.`);
    }
  }

  async fetchProcessSteps(type) {
    try {

      let resp = await ProcStepRequests.getTemplateProcessSteps(type);

      if (resp.status === 200) {

        let steps = await resp.json();

        return steps.map((e, i) => {
          return (
            <MenuItem
              key={i}
              step_name={e.step_name}
              value={e.pk}>
              {e.step_name}
            </MenuItem>
          );
        });

      }

      // Consider other responses to be errors
      this.props.postErrorSnack(`Error: Could not load ${type} steps.`);

    } catch (e) {

      // Error executing fetch
      this.props.postErrorSnack(`Error: Could not load ${type} steps.`);
    }
  }

  validate() {

    const errors = {};
    // color: 'red'
    if(!this.state.part_id){
      errors.part_id = 'Part inner diameter required.';
    }
    if(!this.state.part_od){
      errors.part_od = 'Part outer diameter required.';
    }
    if(!this.state.part_height){
      errors.part_height = 'Part height diameter required.';
    }
    if(!this.state.selected_mixing_value){
      errors.mixing_step = 'Template mixing step required.';
    }
    return errors;
  }

  handleCreateNewMixingStep() {

    this.props.history.push('/app/processSteps/add/mixing/');
  }

  handleStepChange(event) {
    this.props.left_panel.selected_mixing_value = event.target.value;
    const state = copy(this.state);
    state.selected_mixing_value = event.target.value;
    this.setState(state);
  }

  handleNumberChange(name, event) {
    const state = copy(this.state);
    let val = parseFloat(event.target.value);
    switch (name) {
    case 'id':
      state.part_id = val;
      this.props.left_panel.part_id = val;
      break;
    case 'od':
      state.part_od = val;
      this.props.left_panel.part_od = val;
      break;
    case 'height':
      state.part_height = val;
      this.props.left_panel.part_height = val;
      break;
    default:
      break;
    }
    this.setState(state);
  }


  render() {
    const errors = this.validate();
    this.props.left_panel.errors = errors;
    const { classes } = this.props;
    const {mixing_wheel} = this.state;

    return (
      <Fragment>
        {mixing_wheel}
        <FormLabel>
                    Finished Part Dimensions
        </FormLabel>
        <Grid
          container
          direction='row'
          alignItems='stretch'
          spacing={16}
        >
          <Grid container item
            xs={12}
            direction='row'
            spacing={8}
            alignItems='center'
          >
            <Grid item xs={10}>
              <TextField
                select
                label='Mixing Step'
                value={this.state.selected_mixing_value}
                onChange={(event) => { this.handleStepChange(event); }}
                inputProps={{
                  name: 'Mixing Step',
                  id: 'mixingStep',
                }}
                error={errors.mixing_step ? true : false}
                fullWidth>
                <MenuItem value={0}>
                                    Not Selected
                </MenuItem>
                {this.state.mixing_steps}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{margin: 1, height: 40}}
                fullWidth
                //size="small"
                // height={20}
                variant='contained'
                color='primary'
                onClick={this.handleCreateNewMixingStep}>
                                Create
              </Button>
            </Grid>
            {errors.mixing_step ? <Typography classes={{ root: classes.error }} >{errors.mixing_step}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="part_id"
              label='Sintered Part Inner Diameter'
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              onChange={(event) => { this.handleNumberChange('id', event); }}
              error={errors.part_id ? true : false}
            />
            {errors.part_id ? <Typography classes={{ root: classes.error }} >{errors.part_id}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="part_od"
              label='Sintered Part Outer Diameter'
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              onChange={(event) => { this.handleNumberChange('od', event); }}
              error={(errors.part_od) ? true : false}
            />
            {errors.part_od ? <Typography classes={{ root: classes.error }} >{errors.part_od}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth
              id="part_height"
              label='Sintered Part Height'
              type="number"
              variant="outlined"
              p={12}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
              onChange={(event) => { this.handleNumberChange('height', event); }}
              error={(errors.part_height) ? true : false}
            />
            {errors.part_height ? <Typography classes={{ root: classes.error }} >{errors.part_height}</Typography> : <Fragment />}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
DataAnalysisLeftForm.propTypes = {
  classes: PropTypes.object.isRequired,
  left_panel: PropTypes.object.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  postErrorSnack: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
  // mixing_wheel: PropTypes.object.isRequired,
  // action: PropTypes.string.isRequired,
  //handleSaveButton: PropTypes.func.isRequired,
};
export default withStyles(styles)(DataAnalysisLeftForm);

