import Grid from '@material-ui/core/Grid';
import {TextField, InputAdornment} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContainerDimensions from 'react-container-dimensions';
import {
  Chart, Dots, Labels, Lines, Ticks, Title, Animate
} from 'rumble-charts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {extractDataPoints} from '../ProcessSteps/highheat-step-form';


const styles = {
  root: {
    justify: 'center',
    padding: 15,
  },
};

const DEGREES_CELSIUS_SYMBOL = '\xB0C';

class HighheatStepOverviewComponent extends Component {

  render() {
    const step = this.props.step;
    const {
      segments,
      mass_limit,
      number_of_rings,
      ring_type,
      tolerance
    } = step && step.profile ? step.profile : {
      segments: [],
      mass_limit: '',
      number_of_rings: '',
      ring_type: '',
      tolerance: '',
    };

    const data_set = extractDataPoints(segments.map(segment => (
      {
        ...segment,
        duration_hours: Math.floor(segment.duration / 60),
        duration_minutes: segment.duration % 60,
      })
    ));
    const {series, total_duration, ticks} = data_set;
    return (
      <Fragment>
        <Grid container direction='row' justify='center' spacing={16}>
          <Grid item xs={3}/>
          <Grid item xs={4}>
            <TextField fullWidth // className={classes.numericField}
              label='Step Name'
              value={step.step_name || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              InputProps={{
                style: {color: '#303030'},
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth // className={classes.numericField}
              label='Temperature Tolerance'
              type='number'
              value={tolerance || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">{DEGREES_CELSIUS_SYMBOL}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <TextField fullWidth // className={classes.numericField}
              label='Max Temp'
              type='number'
              value={step.t_max || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">{DEGREES_CELSIUS_SYMBOL}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth // className={classes.numericField}
              label='Max Hold'
              type='number'
              value={step.t_hold || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">Min</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth // className={classes.numericField}
              label='Mass Limit'
              type='number'
              value={mass_limit || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              variant="outlined"
              InputProps={{
                style: {color: '#303030'},
                endAdornment: <InputAdornment position="end">Grams</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth // className={classes.numericField}
              label='Number of Rings'
              type='number'
              value={number_of_rings || ''}
              disabled={true}
              InputLabelProps={{
                style: {color: '#303030'},
                shrink: true,
              }}
              InputProps={{
                style: {color: '#303030'},
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField fullWidth // className={classes.numericField}
              label='Ring Type'
              value={ring_type || ''}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                style: {color: '#303030'},
              }}
              InputProps={{
                style: {color: '#303030'},
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Table style={styles.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Segment #</TableCell>
              <TableCell align="right">Segment Type</TableCell>
              <TableCell align="right">Target Setpoint</TableCell>
              <TableCell align="right">Ramp Rate</TableCell>
              <TableCell align="right">Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.map(segment => (
              <TableRow key={segment.index}>
                <TableCell>{segment.index}</TableCell>
                <TableCell align="right">{segment.segment_type === 'dwell' ? 'Dwell' : 'Ramp Rate'}</TableCell>
                <TableCell align="right">{segment.target_setpoint} {DEGREES_CELSIUS_SYMBOL}</TableCell>
                <TableCell align="right">{segment.ramp_rate} {DEGREES_CELSIUS_SYMBOL}/min</TableCell>
                <TableCell align="right">{segment.duration} min</TableCell>
              </TableRow>
            ))}
            {segments.length !== 0 ? <TableRow key={segments.length}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">Total Duration:</TableCell>
              <TableCell align="right">{total_duration} min</TableCell>
            </TableRow> : <Fragment />}
          </TableBody>
        </Table>
        <Grid container direction='row'>
          <Grid key='col1' item xs={12} style={{
            backgroundColor: '#f8f8f8',
            border: '1px solid black',
            margin: 20,
            borderRadius: 12}}>
            <ContainerDimensions>
              {({ width }) =>
                <Chart height={300} width={width - 80}

                  series={series}
                  minY={0}
                  maxY={1240}
                  scaleX={{
                    paddingStart: 80,
                    paddingEnd: 20
                  }}
                  scaleY={{
                    paddingTop: 40,
                    paddingBottom: 0
                  }}
                >

                  <Title>Furnace Profile Visualization</Title>
                  <Animate _ease='bounce'>
                    <Lines asAreas={false} interpolation='linear' />
                    <Dots />
                    <Ticks
                      axis='y'
                      lineLength='100%'
                      lineVisible={true}
                      label={({ index }) => series[index]}
                      lineStyle={{ stroke: 'lightgray' }}
                      labelStyle={{ textAnchor: 'end', dominantBaseline: 'middle' }}
                      labelAttributes={{ x: 65, y: -10 }}
                    />
                    <Ticks
                      ticks={ticks}
                      axis='x'
                      lineHeight='20'
                      lineLength='10'
                      lineVisible={true}
                      label={({ index }) => series[index]}
                      lineStyle={{ stroke: 'lightgray' }}
                      labelStyle={{ textAnchor: 'end', dominantBaseline: 'middle' }}
                      labelAttributes={{ y: -290 }}
                    />
                    <Labels
                      label={({ point }) => `${Math.floor(point.y)}\xB0C`}
                      dotStyle={{
                        alignmentBaseline: 'after-edge',
                        textAnchor: 'middle'
                      }}>
                    </Labels>
                  </Animate>
                  {/* https://rumble-charts.github.io/rumble-charts/#lines */}
                </Chart>
              }
            </ContainerDimensions>
          </Grid>
        </Grid >
      </Fragment>

    );
  }
}

HighheatStepOverviewComponent.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(HighheatStepOverviewComponent);
