import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, AppBar, Toolbar, IconButton, DialogContent, FormControl, LinearProgress, TextField, MenuItem, Typography, Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as PartsRequests from './../rest/PartsRequests';
import * as ProcessOrderRequests from './../rest/ProcessOrderRequests';

class OrderRoughnessForm extends Component {
  state = {
    manualEntry: {
      process_order_id: '',
      part_number: -1,
      q_prime: -1
    },
    isManualEntryValid: false
  }

  componentDidUpdate = () => {
    if (this.props.isOpen && this.state.processOrders === undefined) {
      this.getProcessOrders();
    }
  }

  handleManualProcessOrderEntry = async (e) => {
    let state = this.state;
    state.manualEntry.process_order_id = e.target.value;
    state.manualEntry.part_number = -1;
    state.manualEntry.process_order_name = this.state.processOrders.find(p => p.pk === e.target.value).name;
    state.isManualEntryValid = false;
    if (state.parts !== undefined) {
      delete state.parts;
    }
    this.setState(state);
    let parts = await this.getParts(e.target.value);
    this.setState({parts: parts.filter(p => p.qc !== undefined)});
  }

  handleManualPartEntry = async (e) => {
    if (this.state.parts.length > 0) {
      let { manualEntry } = this.state;
      manualEntry.part_number = e.target.value;
      manualEntry.q_prime = -1;
      this.setState({
        manualEntry: manualEntry,
        isManualEntryValid: false
      });
      let selectedPart = this.state.parts.find(p => {
        let part_number = p.sk.split('#')[1];
        return part_number === e.target.value;
      });
      this.setState({
        selectedPart: selectedPart,
        qPrimes: selectedPart.qc.map(qc => {
          return qc.q_prime;
        })
      });
    }
  }

  handleManualQPrimeEntry = (e) => {
    let { manualEntry } = this.state;
    let testCondition = undefined;
    console.log(this.state.selectedPart);
    if (this.state.selectedPart.qc !== undefined) {
      testCondition = this.state.selectedPart.qc.find(tc => {
        return tc.q_prime === e.target.value;
      });
      console.log(testCondition);
      if (testCondition !== undefined) {
        manualEntry.ra = testCondition.ra;
        manualEntry.rz = testCondition.rz;
      }
    }
    manualEntry.q_prime = e.target.value;
    this.setState({
      manualEntry: manualEntry,
      isManualEntryValid: manualEntry.process_order_id === '' || manualEntry.part_number === -1 ? false : true
    });
  }

  getProcessOrders = async () => {
    let resp = await ProcessOrderRequests.getProcessOrders();
    this.setState({processOrders: await resp.json()});
  }

  getParts = async (process_order_id) => {
    let resp = await PartsRequests.getParts(process_order_id);
    return await resp.json();
  }

  onClose = () => {
    let state = this.state;
    delete state.processOrders;
    Object.assign(state, {
      manualEntry: {
        process_order_id: '',
        part_number: -1,
        q_prime: -1
      },
      isManualEntryValid: false
    });
    this.setState(state);
  }

  render() {
    let { isOpen, handleClose, handleSubmit } = this.props;

    return (
      <Dialog open={isOpen} onClose={() => {handleClose(); this.onClose();}}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => {handleClose(); this.onClose();}} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' style={{color: 'white', margin: 'auto'}}>
              Manual Workpiece Entry
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{padding: 15, width: 250}}>
          <Grid container spacing={16} justify='center'>
            <Grid item xs={12}>
              <FormControl style={{minWidth: '100%'}}>
                {
                  this.state.processOrders === undefined ? (
                    <Fragment>
                      <LinearProgress />
                      <TextField
                        select
                        value={-1}
                        id="processOrderSelect"
                        label="Select Process Order"
                        variant='outlined'
                        style={{width: '100%'}}
                      >
                        <MenuItem key='processOrders' value={-1}>Loading...</MenuItem>
                      </TextField>
                    </Fragment>
                  ) : (
                    <TextField
                      id="processOrderSelect"
                      select
                      label="Select Process Order"
                      value={this.state.manualEntry.process_order_id}
                      onChange={(e) => this.handleManualProcessOrderEntry(e)}
                      variant='outlined'
                      style={{width: '100%'}}
                    >
                      {
                        this.state.processOrders.map(p => {
                          return <MenuItem key={p.pk} value={p.pk}>{p.name}</MenuItem>;
                        })
                      }
                    </TextField>
                  )
                }
              </FormControl>
            </Grid>
            {
              this.state.manualEntry.process_order_id === '' ? (
                <Fragment />
              ) : (
                <Grid item xs={12}>
                  <FormControl style={{minWidth: '100%'}}>
                    {
                      this.state.parts === undefined ? (
                        <Fragment>
                          <LinearProgress />
                          <TextField
                            select
                            id="partManualEntrySelect"
                            value={-1}
                            label="Select Part Number"
                            variant='outlined'
                            style={{minWidth: '100%'}}
                          >
                            <MenuItem key='parts' value={-1}>Loading...</MenuItem>
                          </TextField>
                        </Fragment>
                      ) : (
                        <TextField
                          id="partManualEntrySelect"
                          select
                          label="Select Part Number"
                          value={this.state.manualEntry.part_number}
                          onChange={(e) => this.handleManualPartEntry(e)}
                          variant='outlined'
                          type='number'
                          style={{minWidth: '100%'}}
                        >
                          {
                            this.state.parts.length > 0 ? (
                              this.state.parts.map(p => {
                                let part_number = p.sk.split('#')[1];
                                let key = `${p.pk}#${part_number}`;
                                return <MenuItem key={key} value={part_number}>{part_number}</MenuItem>;
                              })
                            ) : (
                              <MenuItem key='part' value={-1}>No Parts Tested</MenuItem>
                            )
                          }
                        </TextField>
                      )
                    }
                  </FormControl>
                </Grid>
              )
            }
            {
              this.state.manualEntry.part_number === -1 ? (
                <Fragment />
              ) : (
                <Grid item xs={12}>
                  <FormControl style={{minWidth: '100%'}}>
                    {
                      this.state.qPrimes === undefined ? (
                        <Fragment>
                          <LinearProgress />
                          <TextField
                            select
                            id="manualq_primeEntrySelect"
                            value={-1}
                            label="Select Q'"
                            variant='outlined'
                            style={{minWidth: '100%'}}
                          >
                            <MenuItem key='qPrimes' value={-1}>Loading...</MenuItem>
                          </TextField>
                        </Fragment>
                      ) : (
                        <TextField
                          id="manualq_primeEntrySelect"
                          select
                          label="Select Q'"
                          value={this.state.manualEntry.q_prime}
                          onChange={(e) => this.handleManualQPrimeEntry(e)}
                          variant='outlined'
                          style={{minWidth: '100%'}}
                        >
                          {
                            this.state.qPrimes.map(qp => {
                              let { manualEntry } = this.state;
                              let key = `${manualEntry.process_order_id}#${manualEntry.part_number}#${qp}`;
                              return <MenuItem key={key} value={qp}>{qp}</MenuItem>;
                            })
                          }
                        </TextField>
                      )
                    }
                  </FormControl>
                </Grid>
              )
            }
            {
              this.state.isManualEntryValid ? (
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => handleSubmit(this.state.manualEntry)}>
                  Add Workpiece
                  </Button>
                </Grid>
              ) : (
                <Fragment />
              )
            }
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

OrderRoughnessForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default OrderRoughnessForm;